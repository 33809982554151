import { Modal } from '../Modal/Modal';
import {abbreviateNumber, calculateNewTime, secondsToDhms, sliceTime} from '../../utils/common';
import styles from './BuildingInfoModal.module.scss'
import {useTranslation} from "react-i18next";

function BuildingInfoModal({ isOpen, onClose, title, levels, requirements, speedUpPercent, isUsedRelic = false }) {
  const { i18n, t } = useTranslation()
  const lang = i18n?.resolvedLanguage

  if (!isOpen) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <div className={styles.tableWrap}>
        <table className={styles.table}>
          <thead>
          <tr>
            <th className={styles.th}>{t('level')}</th>
            {requirements.includes('food') && (
                <th className={styles.th}>{t('food')}</th>
            )}
            {requirements.includes('iron') && (
                <th className={styles.th}>{t('iron')}</th>
            )}
            {requirements.includes('batteries') && (
                <th className={styles.th}>{t('batteries')}</th>
            )}
            {requirements.includes('genes') && (
                <th className={styles.th}>{t('genes')}</th>
            )}
            {requirements.includes('nuApe') && (
                <th className={styles.th}>{t('nuApe')}</th>
            )}
            <th className={styles.th}>{t('actualTime')}</th>
            <th className={styles.th}>{t('originalTime')}</th>
          </tr>
          </thead>
          <tbody>
          {levels.map((item) => (
              <tr className={styles.tr} key={item.level}>
                <td className={styles.td}>{item.level} > {item.level + 1}</td>
                {requirements.includes('food') && (
                    <td className={styles.td}>{abbreviateNumber(item.requirements.food)}</td>
                )}
                {requirements.includes('iron') && (
                    <td className={styles.td}>{abbreviateNumber(item.requirements.iron)}</td>
                )}
                {requirements.includes('batteries') && (
                    <td className={styles.td}>{abbreviateNumber(item.requirements.batteries)}</td>
                )}
                {requirements.includes('nuApe') && (
                    <td className={styles.td}>{item.requirements.nuApe ? abbreviateNumber(item.requirements.nuApe) : 0}</td>
                )}
                {requirements.includes('genes') && (
                    <td className={styles.td}>{abbreviateNumber(item.requirements.genes)}</td>
                )}
                <td className={styles.td}>{sliceTime(secondsToDhms(calculateNewTime(item.startTime, speedUpPercent, isUsedRelic), lang))}</td>
                <td className={styles.td}>{sliceTime(secondsToDhms(item.startTime, lang))}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

export default BuildingInfoModal
