import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {abbreviateNumber, calculateNewTime, secondsToDhms, sliceTime} from "../../utils/common";
import styles from "./BuildingCard.module.scss";
import BuildingInfoModal from "../BuildingInfoModal/BuildingInfoModal";
import {IconRequirement} from "../../pages/Buildings/Buildings";
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info.svg';

function BuildingCard({building, selectedLevels, speedUpPercent, onSumChange}) {
    const [level, setLevel] = useState(1)
    const [nextLevel, setNextLevel] = useState(2)
    const [requirements, setRequirements] = useState([])
    const [isOpenModalInfo, setIsOpenModalInfo] = useState(false)
    const [maxLevel, setMaxLevel] = useState(0)
    const [sum, setSum] = useState({
        startTime: 0,
        currentTime: 0,
        requirements: {},
    })

    const { i18n, t } = useTranslation()
    const lang = i18n?.resolvedLanguage

    useEffect(() => {
        const values = building.levels

        checkRequirements(values);

        if (selectedLevels) {
            setLevel(selectedLevels.startLevel)
            setNextLevel(selectedLevels.endLevel)
            setMaxLevel(values.length)
        }

    }, [building, selectedLevels]);

    useEffect(() => {
        const values = building.levels

        if (values && selectedLevels) {
            filterNextLevel(values, selectedLevels)
        }

    }, [speedUpPercent, selectedLevels])

    const checkRequirements = (items) => {
        const values = []

        items.forEach((item) => {
            Object.keys(item.requirements).forEach((el) => {
                if (!values.includes(el)) {
                    values.push(el)
                }
            })
        })

        setRequirements(values)
    }

    const handleLevel = (evt) => {
        const value = +evt.target.value;

        if (value <= nextLevel) {
            countSum(building.levels.filter((item) => item.level >= value && item.level < nextLevel), value, selectedLevels.endLevel);
        }
    };

    const handleNextLevel = (evt) => {
        const value = +evt.target.value;

        if (value >= level) {
            countSum(building.levels.filter((item) => item.level >= level && item.level < value), selectedLevels.startLevel, value);
        }
    };

    const filterNextLevel = (values, levels) => {
        countSum(values.filter((item) => item.level >= levels.startLevel && item.level < levels.endLevel));
    }

    const countSum = (values, startLevel, endLevel) => {
        const result = {
            startTime: 0,
            currentTime: 0,
            requirements: {},
        }

        values.forEach(item => {
            result.startTime += item.startTime
            result.currentTime += calculateNewTime(item.startTime, speedUpPercent)

            for (const key in item.requirements) {
                if (result.requirements.hasOwnProperty(key)) {
                    result.requirements[key] += item.requirements[key]
                } else {
                    result.requirements[key] = item.requirements[key]
                }
            }
        })

        setSum(result)
        if (startLevel !== undefined && endLevel !== undefined) {
            onSumChange(result, building.type, startLevel, endLevel)
        } else {
            onSumChange(result, building.type)
        }
    }

    return (
        <div className={styles.card}>
            <button className={styles.buttonInfo} onClick={() => setIsOpenModalInfo(true)}>
                <IconInfo width={20} height={20} />
            </button>

            <h4 className={styles.cardTitle}>{building.names[lang]}</h4>

            <div className={styles.imageWrap}>
                <img className={styles.image} src={building.img} alt={building.name}/>
            </div>

            <div>
                <p className={styles.requirementsLabel}>
                    <span>{t('consumptionFor')}</span>
                    <select className={styles.select} onChange={handleLevel}>
                        {building?.levels.map((el, index) => (
                            <option
                                value={el.level}
                                selected={selectedLevels?.startLevel === el.level}
                                disabled={el.level > selectedLevels?.endLevel}
                                key={el.level}
                            >
                                {el.level}
                            </option>
                        ))}
                        <option
                            value={building?.levels?.length + 1}
                            selected={selectedLevels?.startLevel === (building?.levels?.length + 1)}
                            disabled={(building?.levels?.length + 1) > selectedLevels?.endLevel}
                        >
                            {building?.levels?.length + 1}
                        </option>
                    </select>
                    <span>></span>

                    <select className={styles.select} onChange={handleNextLevel}>
                        {building?.levels.map((el, index) => (
                            <option
                                value={el.level} selected={selectedLevels?.endLevel === el.level}
                                disabled={el.level < selectedLevels?.startLevel}
                                key={el.level}
                            >
                                {el.level}
                            </option>
                        ))}
                        <option
                            value={building?.levels?.length + 1}
                            selected={selectedLevels?.endLevel === (building?.levels?.length + 1)}
                            disabled={(building?.levels?.length + 1) < selectedLevels?.startLevel}
                        >
                            {building?.levels?.length +1}
                        </option>
                    </select>
                </p>
            </div>

            {Object.keys(sum.requirements).length > 0 && (
                <div className={styles.content}>
                <div className={styles.requirements}>
                        {Object.entries(sum.requirements).map(([key, value]) => (
                            <div className={styles.requirement} key={key}>
                                <IconRequirement width={18} height={18} iconKey={key}/>
                                <span>{abbreviateNumber(value)}</span>
                            </div>
                        ))}
                    </div>

                    <div className={styles.bottom}>
                        <p className={styles.timeWrap}>
                            <span>{t('time')}:</span>
                            <span
                                className={styles.timeBuilding}>{sliceTime(secondsToDhms(sum.currentTime, lang))}</span>
                        </p>
                    </div>
                </div>
            )}

            <BuildingInfoModal
                isOpen={isOpenModalInfo}
                onClose={() => setIsOpenModalInfo(false)}
                title={building.names[lang]}
                levels={building.levels}
                requirements={requirements}
                speedUpPercent={speedUpPercent}
            />
        </div>
    )
}

export default BuildingCard;
