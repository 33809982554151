import styles from './EventsPage.module.scss'
import Header from "../../components/Header/Header"
import Container from "../../components/Container/Container"
import {useTranslation} from "react-i18next"
import {Helmet} from "react-helmet"
import {useState} from "react"
import KingOfApes from "../../components/KingOfApes/KingOfApes"
import InterstellarClash from "../../components/InterstellarClash/InterstellarClash"
import PrepareForLaunch from "../../components/PrepareForLaunch/PrepareForLaunch"
import {useNavigate, useParams} from "react-router-dom";

function EventsPage() {
    const { t } = useTranslation()
    const { section } = useParams()
    const navigate = useNavigate()
    const [tabActive, setTabActive] = useState(section || 'king-of-apes')

    const handleChangeTab = (tab) => {
        setTabActive(tab)
        navigate(`/events/${tab}`)
    }

    return (
        <>
            <Helmet>
                <title>{t('header.item5')}</title>
            </Helmet>

            <Header />

            <Container>
                <div className={styles.tabs}>
                    <button
                        className={`${styles.tab} ${tabActive === 'king-of-apes' ? styles.tabActive : ''}`}
                        onClick={() => handleChangeTab('king-of-apes')}
                    >
                        {t('events.kingOfApes')}
                    </button>
                    <button
                        className={`${styles.tab} ${tabActive === 'interstellar-clash' ? styles.tabActive : ''}`}
                        onClick={() => handleChangeTab('interstellar-clash')}
                    >
                        {t('events.interstellarClash')}
                    </button>
                    <button
                        className={`${styles.tab} ${tabActive === 'prepare-for-launch' ? styles.tabActive : ''}`}
                        onClick={() => handleChangeTab('prepare-for-launch')}
                    >
                        {t('events.prepareForLaunch')}
                    </button>
                </div>

                <div>
                    {tabActive === 'king-of-apes' && (
                        <KingOfApes />
                    )}

                    {tabActive === 'interstellar-clash' && (
                        <InterstellarClash />
                    )}

                    {tabActive === 'prepare-for-launch' && (
                        <PrepareForLaunch />
                    )}
                </div>
            </Container>
        </>
    )
}

export default EventsPage;
