import styles from './RelicsPage.module.scss'
import {Helmet} from "react-helmet";
import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import {useTranslation} from "react-i18next";
import baseStoneImg from '../../assets/images/icons/icon-base-stone.png'
import rareStoneImg from '../../assets/images/icons/icon-rare-stone.png'
import epicStoneImg from '../../assets/images/icons/icon-epic-stone.png'
import legStoneImg from '../../assets/images/icons/icon-leg-stone.png'
import superStoneImg from '../../assets/images/icons/icon-super-stone.png'
import starImg from '../../assets/images/icons/icon-star.png'

function RelicsPage() {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('header.item6')}</title>
            </Helmet>

            <Header />

            <Container>
                <h1>{t('header.item6')}</h1>

                <div className={styles.tableWrap}>
                    <p className={styles.tableTitle}>{t('relics.costUpgradeStars')}</p>

                    <div className={styles.overflowContainer}>
                        <table className={`${styles.table} ${styles.tableFirst}`}>
                            <thead>
                            <tr>
                                <th>
                                    <img className={styles.icon} src={starImg} width={16} height={16} alt=""/> {t('relics.stars')}
                                </th>
                                <th className={styles.base}>
                                    {t('relics.basic')}
                                </th>
                                <th className={styles.rare}>
                                    {t('relics.rare')}
                                </th>
                                <th className={styles.epic}>
                                    {t('relics.epic')}
                                </th>
                                <th className={styles.leg}>
                                    {t('relics.legendary')}
                                </th>
                                <th className={styles.super}>
                                    {t('relics.super')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1 > 2</td>
                                <td className={styles.base}>
                                    <img className={styles.icon} src={baseStoneImg} width={16} height={16} alt=""/> 20
                                </td>
                                <td className={styles.rare}>
                                    <img className={styles.icon} src={rareStoneImg} width={16} height={16} alt=""/> 20
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 40
                                </td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 50
                                </td>
                                <td className={styles.super}></td>
                            </tr>
                            <tr>
                                <td>2 > 3</td>
                                <td className={styles.base}>
                                    <img className={styles.icon} src={baseStoneImg} width={16} height={16} alt=""/> 40
                                </td>
                                <td className={styles.rare}>
                                    <img className={styles.icon} src={rareStoneImg} width={16} height={16} alt=""/> 40
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 60
                                </td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 80
                                </td>
                                <td className={styles.super}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 100
                                </td>
                            </tr>
                            <tr>
                            <td>3 > 4</td>
                                <td className={styles.base}></td>
                                <td className={styles.rare}>
                                    <img className={styles.icon} src={rareStoneImg} width={16} height={16} alt=""/> 60
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 80
                                </td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 100
                                </td>
                                <td className={styles.super}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 140
                                </td>
                            </tr>
                            <tr>
                            <td>4 > 5</td>
                                <td className={styles.base}></td>
                                <td className={styles.rare}></td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 100
                                </td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 150
                                </td>
                                <td className={styles.super}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 200
                                </td>
                            </tr>
                            <tr className={styles.total}>
                                <td>{t('total')}:</td>
                                <td className={styles.base}>
                                    <img className={styles.icon} src={baseStoneImg} width={16} height={16} alt=""/> 60
                                </td>
                                <td className={styles.rare}>
                                    <img className={styles.icon} src={rareStoneImg} width={16} height={16} alt=""/> 120
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 280
                                </td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 380
                                </td>
                                <td className={styles.super}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 440
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={styles.leapTables}>
                    <div className={styles.tableWrap}>
                        <p className={styles.tableTitle}>{t('relics.costLeapEpic')}</p>

                        <table className={`${styles.table} ${styles.tableWithoutBorders}`}>
                            <thead>
                            <tr>
                                <th>
                                    <img className={styles.icon} src={starImg} width={16} height={16} alt=""/> {t('relics.stars')}
                                </th>
                                <th className={styles.epic} colSpan={2}>
                                    {t('relics.epic')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>5 > 1</td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 60
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 25
                                </td>
                            </tr>
                            <tr>
                            <td>1 > 2</td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 120
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 40
                                </td>
                            </tr>
                            <tr>
                            <td>2 > 3</td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 180
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 65
                                </td>
                            </tr>
                            <tr>
                            <td>3 > 4</td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 240
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 80
                                </td>
                            </tr>
                            <tr>
                            <td>4 > 5</td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 300
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 120
                                </td>
                            </tr>
                            <tr className={styles.total}>
                                <td>{t('total')}:</td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={epicStoneImg} width={16} height={16} alt=""/> 900
                                </td>
                                <td className={styles.epic}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 330
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.tableWrap}>
                        <p className={styles.tableTitle}>{t('relics.costLeapLeg')}</p>

                        <table className={`${styles.table} ${styles.tableWithoutBorders}`}>
                            <thead>
                            <tr>
                                <th>
                                    <img className={styles.icon} src={starImg} width={16} height={16} alt=""/> {t('relics.stars')}
                                </th>
                                <th className={styles.leg} colSpan={2}>
                                    {t('relics.legendary')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>5 > 1</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 60</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 20</td>
                            </tr>
                            <tr>
                                <td>1 > 2</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 100</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 30</td>
                            </tr>
                            <tr>
                                <td>2 > 3</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 160</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 50</td>
                            </tr>
                            <tr>
                                <td>3 > 4</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 200</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 70</td>
                            </tr>
                            <tr>
                                <td>4 > 5</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 300</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 100</td>
                            </tr>
                            <tr className={styles.total}>
                                <td>{t('total')}:</td>
                                <td className={styles.leg}>
                                    <img className={styles.icon} src={legStoneImg} width={16} height={16} alt=""/> 820
                                </td>
                                <td className={styles.leg}>
                                <img className={styles.icon} src={superStoneImg} width={16} height={16} alt=""/> 270
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default RelicsPage
