import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Buildings from './pages/Buildings/Buildings';
// import Calculator from './pages/Calculator/Calculator';
import ResearchT6Page from "./pages/ResearchT6Page/ResearchT6Page";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import InvestPage from "./pages/InvestPage/InvestPage";
import EventsPage from "./pages/EventsPage/EventsPage";
import RelicsPage from "./pages/RelicsPage/RelicsPage";
import WeaponsPage from "./pages/WeaponsPage/WeaponsPage";

function App() {
    const { i18n } = useTranslation();

  return (
    <>
        <Helmet>
            <html lang={i18n.language} />
        </Helmet>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ResearchT6Page />} />
                <Route path="/buildings" element={<Buildings />} />
                <Route path="/invest" element={<InvestPage />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/events/:section" element={<EventsPage />} />
                <Route path="/weapons" element={<WeaponsPage />} />
                <Route path="/relics" element={<RelicsPage />} />
                {/*<Route path="/calculator" element={<Calculator />} />*/}
                {/*<Route path="/research-t6" element={<ResearchT6Page />} />*/}
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
