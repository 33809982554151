import styles from './Header.module.scss';
import { NavLink } from 'react-router-dom';
import Container from "../Container/Container";
import {useTranslation} from "react-i18next";
import i18next from "i18next";


function Header() {
    const { i18n, t } = useTranslation()
    const lang = i18n?.resolvedLanguage

    const handleChangeLang = (langCode) => {
        i18next.changeLanguage(langCode)
        document.documentElement.lang = i18n.language
    }

    return (
        <header className={styles.header}>
            <Container>
                <div className={styles.inner}>
                    <nav className={styles.nav}>
                        <NavLink
                            to="/"
                            className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}
                        >
                            {t('header.item2')}
                        </NavLink>
                        <NavLink
                            to="/buildings"
                            className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}
                        >
                            {t('header.item1')}
                        </NavLink>
                        <NavLink
                            to="/events"
                            className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}
                        >
                            {t('header.item5')}
                        </NavLink>
                        <NavLink
                            to="/weapons"
                            className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}
                        >
                            {t('header.item7')}
                        </NavLink>
                        <NavLink
                            to="/relics"
                            className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}
                        >
                            {t('header.item6')}
                        </NavLink>
                        <NavLink
                            to="/invest"
                            className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}
                        >
                            {t('header.item4')}
                        </NavLink>
                        {/*<NavLink*/}
                        {/*    to="/calculator"*/}
                        {/*    className={props => `${props.isActive ? styles.active : ''} ${styles.navLink}`}*/}
                        {/*>*/}
                        {/*    {t('header.item3')}*/}
                        {/*</NavLink>*/}
                    </nav>

                    <div className={styles.langWrap}>
                        <button
                            className={`${styles.langBtn} ${lang === 'ru' ? styles.langBtnActive : ''}`}
                            onClick={() => handleChangeLang('ru')}
                        >
                            РУС
                        </button>
                        {' | '}
                        <button
                            className={`${styles.langBtn} ${lang === 'en' ? styles.langBtnActive : ''}`}
                            onClick={() => handleChangeLang('en')}
                        >
                            ENG
                        </button>
                    </div>
                </div>
            </Container>
        </header>
    );
}

export default Header;
