import styles from './WeaponsPage.module.scss'
import {Helmet} from "react-helmet"
import {useTranslation} from "react-i18next"
import Header from "../../components/Header/Header"
import Container from "../../components/Container/Container"
import {useEffect, useState} from "react"
import IconBox from '../../assets/images/icons/icon-materials-box.png'
import IconBlueprint from '../../assets/images/icons/icon-blueprint.png'
import IconPolymer from '../../assets/images/icons/icon-polymer.png'

const weapons = [
    {
        rarity: 'rare',
        level: 0,
        box: 50,
        blueprint: 0,
        polymer: 0,
        type: "Т3",
    },
    {
        rarity: 'rare',
        level: 1,
        box: 100,
        blueprint: 0,
        polymer: 0,
        type: "Т3",
    },
    {
        rarity: 'rare',
        level: 2,
        box: 200,
        blueprint: 5,
        polymer: 0,
        type: "Т3",
    },
    {
        rarity: 'rare',
        level: 3,
        box: 150,
        blueprint: 20,
        polymer: 0,
        type: "Т3",
    },
    {
        rarity: 'rareEpic',
        level: 4,
        box: 500,
        blueprint: 15,
        polymer: 0,
        type: "Т3 > Т4",
    },
    {
        rarity: 'epic',
        level: 0,
        box: 1000,
        blueprint: 50,
        polymer: 0,
        type: "Т4",
    },
    {
        rarity: 'epic',
        level: 1,
        box: 1200,
        blueprint: 70,
        polymer: 0,
        type: "Т4",
    },
    {
        rarity: 'epic',
        level: 2,
        box: 1500,
        blueprint: 40,
        polymer: 0,
        type: "Т4",
    },
    {
        rarity: 'epic',
        level: 3,
        box: 2200,
        blueprint: 30,
        polymer: 0,
        type: "Т4",
    },
    {
        rarity: 'epic',
        level: 4,
        box: 3000,
        blueprint: 40,
        polymer: 0,
        type: "Т4",
    },
    {
        rarity: 'epicLeg',
        level: 5,
        box: 3200,
        blueprint: 15,
        polymer: 0,
        type: "Т4 > Т5",
    },
    {
        rarity: 'leg',
        level: 0,
        box: 3500,
        blueprint: 20,
        polymer: 0,
        type: "Т5",
    },
    {
        rarity: 'leg',
        level: 1,
        box: 3800,
        blueprint: 30,
        polymer: 0,
        type: "Т5",
    },
    {
        rarity: 'leg',
        level: 2,
        box: 4000,
        blueprint: 40,
        polymer: 0,
        type: "Т5",
    },
    {
        rarity: 'leg',
        level: 3,
        box: 4200,
        blueprint: 60,
        polymer: 0,
        type: "Т5",
    },
    {
        rarity: 'leg',
        level: 4,
        box: 4500,
        blueprint: 80,
        polymer: 0,
        type: "Т5",
    },
    {
        rarity: 'leg',
        level: 5,
        box: 5000,
        blueprint: 100,
        polymer: 0,
        type: "Т5",
    },
    {
        rarity: 'legSuperGold',
        level: 6,
        box: 3000,
        blueprint: 30,
        polymer: 3,
        type: "Т5 > Т6 з.",
    },
    {
        rarity: 'superGold',
        level: 0,
        box: 5200,
        blueprint: 110,
        polymer: 20,
        type: "Т6 з.",
    },
    {
        rarity: 'superGold',
        level: 1,
        box: 5600,
        blueprint: 135,
        polymer: 30,
        type: "Т6 з.",
    },
    {
        rarity: 'superGold',
        level: 2,
        box: 6000,
        blueprint: 160,
        polymer: 40,
        type: "Т6 з.",
    },
    {
        rarity: 'superGold',
        level: 3,
        box: 6400,
        blueprint: 200,
        polymer: 70,
        type: "Т6 з.",
    },
    {
        rarity: 'superGold',
        level: 4,
        box: 6800,
        blueprint: 240,
        polymer: 115,
        type: "Т6 з.",
    },
    {
        rarity: 'superGold',
        level: 5,
        box: 7300,
        blueprint: 285,
        polymer: 170,
        type: "Т6 з.",
    },
]

function SumRow({ index, sum, isX4 = false }) {
    const nextRow = weapons[index +1]
    const transferRarity = ['rareEpic', 'epicLeg', 'legSuperGold']

    if (nextRow && (nextRow.rarity === weapons[index].rarity || transferRarity.includes(weapons[index].rarity))) return null

    return (
        <div className={`${styles.row} ${styles.rowSum} ${isX4 ? styles.rowX4 : ""}`}>
            {isX4 ? null : <p />}
            <p>{(isX4 ? sum[weapons[index].rarity]?.box * 4 : sum[weapons[index].rarity]?.box)?.toLocaleString()}</p>
            <p>{(isX4 ? sum[weapons[index].rarity]?.blueprint * 4 : sum[weapons[index].rarity]?.blueprint)?.toLocaleString()}</p>
            <p>{(isX4 ? sum[weapons[index].rarity]?.polymer * 4 : sum[weapons[index].rarity]?.polymer)?.toLocaleString()}</p>
        </div>
    )
}

function WeaponsPage() {
    const {t} = useTranslation()
    const [sums, setSums] = useState({})

    useEffect(() => {
        const newSums = {
            rare: {
                box: 0,
                blueprint: 0,
                polymer: 0,
            },
            epic: {
                box: 0,
                blueprint: 0,
                polymer: 0,
            },
            leg: {
                box: 0,
                blueprint: 0,
                polymer: 0,
            },
            superGold: {
                box: 0,
                blueprint: 0,
                polymer: 0,
            },
        }

        weapons.forEach((item) => {
            if (newSums.hasOwnProperty(item.rarity)) {
                newSums[item.rarity].box += item.box
                newSums[item.rarity].blueprint += item.blueprint
                newSums[item.rarity].polymer += item.polymer
            } else if (item.rarity === 'rareEpic') {
                newSums.epic.box += item.box
                newSums.epic.blueprint += item.blueprint
                newSums.epic.polymer += item.polymer
            } else if (item.rarity === 'epicLeg') {
                newSums.leg.box += item.box
                newSums.leg.blueprint += item.blueprint
                newSums.leg.polymer += item.polymer
            } else if (item.rarity === 'legSuperGold') {
                newSums.superGold.box += item.box
                newSums.superGold.blueprint += item.blueprint
                newSums.superGold.polymer += item.polymer
            }
        })

        setSums(newSums)
    }, [])

    const returnNextLevel = (item) => {
        const transferRarity = ['rareEpic', 'epicLeg', 'legSuperGold']

        if (transferRarity.includes(item.rarity)) {
            return 0
        }

        return item.level + 1
    }

    return (
        <>
            <Helmet>
                <title>{t('header.item7')}</title>
            </Helmet>

            <Header />

            <Container>
                <div className={styles.card}>
                    <div className={styles.cardInner}>
                        <div>
                            <div className={styles.row}>
                                <p className={styles.rh}>{t('level')}</p>
                                <p className={styles.rh}>
                                    <img className={styles.icon} src={IconBox} width={32} height={32} alt=""/>
                                </p>
                                <p className={styles.rh}>
                                    <img className={styles.icon} src={IconBlueprint} width={32} height={32} alt=""/>
                                </p>
                                <p className={styles.rh}>
                                    <img className={styles.icon} src={IconPolymer} width={32} height={32} alt=""/>
                                </p>
                            </div>
                            {weapons.map((weapon, index) => (
                                <>
                                    <div className={`${styles.row} ${styles[weapon.rarity]}`}>
                                        <p>{weapon.level} > {returnNextLevel(weapon)}</p>
                                        <p>{weapon.box?.toLocaleString()}</p>
                                        <p>{weapon.blueprint?.toLocaleString()}</p>
                                        <p>{weapon.polymer?.toLocaleString()}</p>
                                    </div>

                                    <SumRow sum={sums} index={index}/>
                                </>
                            ))}
                            <div className={`${styles.row} ${styles.rowSum} ${styles.rowTotal}`}>
                                <p>{t('total')}</p>
                                <p>{Object.values(sums).reduce((a, b) => a + b.box, 0)?.toLocaleString()}</p>
                                <p>{Object.values(sums).reduce((a, b) => a + b.blueprint, 0)?.toLocaleString()}</p>
                                <p>{Object.values(sums).reduce((a, b) => a + b.polymer, 0)?.toLocaleString()}</p>
                            </div>
                        </div>

                        <div>
                            <div className={`${styles.row} ${styles.rowX4}`}>
                                <p className={styles.rh}>
                                    <img className={styles.icon} src={IconBox} width={32} height={32} alt=""/> x4
                                </p>
                                <p className={styles.rh}>
                                    <img className={styles.icon} src={IconBlueprint} width={32} height={32} alt=""/> x4
                                </p>
                                <p className={styles.rh}>
                                    <img className={styles.icon} src={IconPolymer} width={32} height={32} alt=""/> x4
                                </p>
                            </div>
                            {weapons.map((weapon, index) => (
                                <>
                                    <div className={`${styles.row} ${styles.rowX4} ${styles[weapon.rarity]}`}>
                                        <p>{(weapon.box * 4)?.toLocaleString()}</p>
                                        <p>{(weapon.blueprint * 4)?.toLocaleString()}</p>
                                        <p>{(weapon.polymer * 4)?.toLocaleString()}</p>
                                    </div>

                                    <SumRow sum={sums} index={index} isX4/>
                                </>
                            ))}
                            <div className={`${styles.row} ${styles.rowSum} ${styles.rowX4} ${styles.rowTotal}`}>
                                <p>{(Object.values(sums).reduce((a, b) => a + b.box, 0) * 4)?.toLocaleString()}</p>
                                <p>{(Object.values(sums).reduce((a, b) => a + b.blueprint, 0) * 4)?.toLocaleString()}</p>
                                <p>{(Object.values(sums).reduce((a, b) => a + b.polymer, 0) * 4)?.toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <p className={styles.cardLabel}>{t('weapons.colorMeaning')}</p>

                    <div className={styles.legendList}>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.rare}`}>
                                <p className={styles.legendLabel}>Т3</p>
                            </div>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.rareEpic}`}>
                                <p className={styles.legendLabel}>{t('weapons.transitionFromT3toT4')}</p>
                            </div>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.epic}`}>
                                <p className={styles.legendLabel}>Т4</p>
                            </div>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.epicLeg}`}>
                                <p className={styles.legendLabel}>{t('weapons.transitionFromT4toT5')}</p>
                            </div>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.leg}`}>
                                <p className={styles.legendLabel}>Т5</p>
                            </div>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.legSuperGold}`}>
                                <p className={styles.legendLabel}>{t('weapons.transitionFromT5toT6Gold')}</p>
                            </div>
                        </div>
                        <div className={styles.legendItem}>
                            <div className={`${styles.legendBox} ${styles.superGold}`}>
                                <p className={styles.legendLabel}>{t('weapons.t6Gold')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default WeaponsPage
