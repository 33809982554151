import styles from './InterstellarClash.module.scss'
import {useTranslation} from "react-i18next"
import {EventItemIcon} from "../EventItemIcon/EventItemIcon";

const days = [
    [
        {
            title: 'koa.fighterUpgrade.title',
            items: [
                {
                    label: 'koa.fighterUpgrade.item1',
                    iconKey: 'genePotion',
                    value: 10,
                },
                {
                    label: 'koa.fighterUpgrade.item2',
                    iconKey: 'legendaryMedal',
                    value: 10000,
                },
            ],
        },
        {
            title: 'koa.speedUp.title',
            items: [
                {
                    label: 'koa.speedUp.item1',
                    iconKey: 'speedup',
                    value: 3,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.starUpRelics.title',
            items: [
                {
                    label: 'koa.starUpRelics.item1',
                    iconKey: 'legStone',
                    value: 1000,
                },
                {
                    label: 'koa.starUpRelics.item2',
                    iconKey: 'superStone',
                    value: 1600,
                },
            ],
        },
        {
            title: 'koa.speedUp.title',
            items: [
                {
                    label: 'koa.speedUp.item1',
                    iconKey: 'speedup',
                    value: 3,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.gearAdvancement.title',
            items: [
                {
                    label: 'koa.gearAdvancement.item1',
                    iconKey: 'box',
                    value: 60,
                },
                {
                    label: 'koa.gearAdvancement.item2',
                    iconKey: 'blueprint',
                    value: 1500,
                },
                {
                    label: 'koa.gearAdvancement.item3',
                    iconKey: 'polymer',
                    value: 4000,
                },
            ],
        },
        {
            title: 'koa.speedUp.title',
            items: [
                {
                    label: 'koa.speedUp.item1',
                    iconKey: 'speedup',
                    value: 3,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.mechUpgrade.title',
            items: [
                {
                    label: 'koa.mechUpgrade.item1',
                    iconKey: 'mechForce',
                    value: 15,
                },
                {
                    label: 'koa.mechUpgrade.item2',
                    iconKey: 'chargeChip',
                    value: 10000,
                },
                {
                    label: 'koa.mechUpgrade.item3',
                    iconKey: 'utilityCore',
                    value: 7000,
                },
            ],
        },
        {
            title: 'interstellarClash.equipmentUpgrade.title',
            items: [
                {
                    label: 'interstellarClash.equipmentUpgrade.item1',
                    iconKey: 'equipment',
                    value: 10,
                },
            ],
        },
        {
            title: 'koa.speedUp.title',
            items: [
                {
                    label: 'koa.speedUp.item1',
                    iconKey: 'speedup',
                    value: 3,
                },
            ],
        },
    ],
]

const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function InterstellarClash() {
    const { t } = useTranslation()
    const currentDate = new Date()
    const dayNumber = currentDate.getUTCDay()

    return (
        <div className={styles.days}>
            {days.map((day, index) => (
                <div className={styles.day}>
                    <p className={`${styles.dayTitle} ${dayNumber === (index + 1) ? styles.dayTitleActive : ''}`}>
                        {t('day')} {index + 1} ({t(`daysOfTheWeek.${daysOfTheWeek[index]}`)})
                    </p>

                    {day.map((el, index) => (
                        <div className={styles.list} key={el.title}>
                            <p className={styles.listLabel}>{t(el.title)}</p>

                            {el.items.map((item, index) => (
                                <p className={styles.item} key={item.value}>
                                    <p className={styles.itemLabelWrap}>
                                        <EventItemIcon iconKey={item.iconKey} />
                                        <span className={styles.itemLabel}>{t(item.label)}</span>
                                    </p>
                                    <span className={styles.itemValue}>+{item.value?.toLocaleString()}</span>
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default InterstellarClash
