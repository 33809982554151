import styles from './SpeedUpPercent.module.scss'
import {useTranslation} from "react-i18next"
import {useEffect} from "react"

function SpeedUpPercent({ percent, setPercent, localStorageKey }) {
    const { t } = useTranslation()

    useEffect(() => {
        const localStorageValue = localStorage.getItem(localStorageKey)

        if (localStorageValue) {
            setPercent(Number(localStorageValue))
        }
    }, [])

    const handleChangePercent = (evt) => {
        const value = Number(evt.target.value)

        setPercent(value)
        localStorage.setItem(localStorageKey, JSON.stringify(value))
    }

    return (
        <>
            <div className={styles.main}>
                {t('speedBoost')} {' '}
                <input
                    className={styles.input}
                    value={percent || ''}
                    onChange={handleChangePercent}
                    type="number"
                /> {' '}
                %
            </div>
        </>
    )
}

export default SpeedUpPercent
