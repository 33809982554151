import iconBox from "../../assets/images/icons/icon-materials-box.png"
import iconBlueprint from "../../assets/images/icons/icon-blueprint.png"
import iconPolymer from "../../assets/images/icons/icon-polymer.png"
import legStoneImg from "../../assets/images/icons/icon-leg-stone.png"
import superStoneImg from "../../assets/images/icons/icon-super-stone.png"
import troopsImg from "../../assets/images/icons/icon-troops.png"
import luckyCoinImg from "../../assets/images/icons/icon-lucky-coin.png"
import neutralImg from "../../assets/images/icons/icon-neutral.png"
import gatheringImg from "../../assets/images/icons/icon-gathering.png"
import genePotionImg from "../../assets/images/icons/icon-gene-potion.png"
import legendaryMedalImg from "../../assets/images/icons/icon-legendary-medal.png"
import equipmentImg from "../../assets/images/icons/icon-equipment.png"
import mechForceImg from "../../assets/images/icons/icon-mech-force.png"
import chargeChipImg from "../../assets/images/icons/icon-charge-chip.png"
import utilityCoreImg from "../../assets/images/icons/icon-utility-core.png"
import speedupImg from "../../assets/images/icons/icon-speedup.png"
import totalVictoryImg from "../../assets/images/icons/icon-total-victory.png"
import powerImg from "../../assets/images/icons/icon-power.png"

export function EventItemIcon({ iconKey }) {
    console.log(iconKey)
    if (iconKey === 'box') {
        return <img src={iconBox} width={24} height={24} alt=""/>
    } else if (iconKey === 'blueprint') {
        return <img src={iconBlueprint} width={24} height={24} alt=""/>
    } else if (iconKey === 'polymer') {
        return <img src={iconPolymer} width={24} height={24} alt=""/>
    } else if (iconKey === 'legStone') {
        return <img src={legStoneImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'superStone') {
        return <img src={superStoneImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'troops') {
        return <img src={troopsImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'luckyCoin') {
        return <img src={luckyCoinImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'neutral') {
        return <img src={neutralImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'gathering') {
        return <img src={gatheringImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'genePotion') {
        return <img src={genePotionImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'legendaryMedal') {
        return <img src={legendaryMedalImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'equipment') {
        return <img src={equipmentImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'mechForce') {
        return <img src={mechForceImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'chargeChip') {
        return <img src={chargeChipImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'utilityCore') {
        return <img src={utilityCoreImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'speedup') {
        return <img src={speedupImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'totalVictory') {
        return <img src={totalVictoryImg} width={24} height={24} alt=""/>
    } else if (iconKey === 'power') {
        return <img src={powerImg} width={24} height={24} alt=""/>
    }

    return null
}