export const secondsToDhms = (seconds, lng = 'en') => {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600*24));
  let h = Math.floor(seconds % (3600*24) / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  let s = Math.floor(seconds % 60);

  const dStr = lng === 'en' ? 'd ' : 'д '
  const hStr = lng === 'en' ? 'h ' : 'ч '
  const mStr = lng === 'en' ? 'm ' : 'м '
  const sStr = lng === 'en' ? 's ' : 'с '

  let dDisplay = d > 0 ? d + dStr : '';
  let hDisplay = h > 0 ? h + hStr : '';
  let mDisplay = m > 0 ? m + mStr : '';
  let sDisplay = s > 0 ? s + sStr : '';

  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const calculateNewTime = (initialTime, acceleration, isTotem = false) => {
  if (acceleration > 0) {
    // Преобразование процента ускорения в десятичную форму
    const accelerationDecimal = acceleration / 100;
    // Расчет нового времени
    const newTime = (initialTime / (1 + accelerationDecimal))

    return isTotem ? newTime - 2800 : newTime;
  }

  return initialTime
}

export const abbreviateNumber = (number) => {
  if (number < 1000) {
    return number.toString();
  }
  const units = ['K', 'M', 'B', 'T']; // Тысячи, Миллионы, Миллиарды, Триллионы
  const unitIndex = Math.floor(Math.log10(number) / 3) - 1;
  const abbreviated = (number / Math.pow(1000, unitIndex + 1)).toFixed(1);
  return `${abbreviated}${units[unitIndex]}`;
}

export const sliceTime = (string) => {
  const parts = string.split(" ")

  if (parts.length < 2) {
    return string;
  }

  return `${parts[0]} ${parts[1]}`
}
