import styles from './Container.module.scss';


function Container({ children, className }) {
    return (
        <div className={`${styles._container} ${className ? styles[className] : ''}`}>
            {children}
        </div>
    );
}

export default Container;
