import speedBombImg from '../assets/images/weapons-technology/speed-bomb.webp'
import precisionSniperImg from '../assets/images/weapons-technology/precision-sniper.webp'
import dynamoHammerImg from '../assets/images/weapons-technology/dynamo-hammer.webp'
import velocityPenetratorImg from '../assets/images/weapons-technology/velocity-penetrator.webp'
import stardustBombsImg from '../assets/images/weapons-technology/stardust-bombs.webp'
import momentumShieldImg from '../assets/images/weapons-technology/momentum-shield.webp'
import securityBarrierImg from '../assets/images/weapons-technology/security-barrier.webp'
import apeOfSteelImg from '../assets/images/weapons-technology/ape-of-steel.webp'
import bulletproofChestImg from '../assets/images/weapons-technology/bulletproof-chest.webp'
import unitedWeStandImg from '../assets/images/weapons-technology/united-we-stand.webp'
import interstellarTravelImg from '../assets/images/weapons-technology/interstellar-travel.webp'
import spaceWarriorImg from '../assets/images/weapons-technology/space-warrior.webp'
import timeToSoarImg from '../assets/images/weapons-technology/time-to-soar.webp'
import hurricaneBulletsImg from '../assets/images/weapons-technology/hurricane-bullets.webp'
import forceOfSpeedImg from '../assets/images/weapons-technology/force-of-speed.webp'
import chariotAssaultImg from '../assets/images/weapons-technology/chariot-assault.webp'
import blastCoresImg from '../assets/images/weapons-technology/blast-cores.webp'
import pulseSniperImg from '../assets/images/weapons-technology/pulse-sniper.webp'
import hiEnergyHammerImg from '../assets/images/weapons-technology/hi-energy-hammer.webp'
import heavyExplosivesImg from '../assets/images/weapons-technology/heavy-explosives.webp'
import spaceWalkImg from '../assets/images/weapons-technology/space-walk.webp'
import earthshakersImg from '../assets/images/weapons-technology/earthshakers.webp'

export const weaponsTechnology = {
    speedBomb: {
        image: speedBombImg,
        names: {
            ru: 'Бомба Скорости',
            en: 'Speed Bomb',
        },
        id: 'speedBomb',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [],
            },
        ],
    },
    precisionSniper: {
        image: precisionSniperImg,
        names: {
            ru: 'Высокоточный снайпер',
            en: 'Precision Sniper',
        },
        id: 'precisionSniper',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [],
            },
        ],
    },
    dynamoHammer: {
        image: dynamoHammerImg,
        names: {
            ru: 'Динамо-молоток',
            en: 'Dynamo Hammer',
        },
        id: 'dynamoHammer',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [],
            },
        ],
    },
    velocityPenetrator: {
        image: velocityPenetratorImg,
        names: {
            ru: 'Проникающий Скоростник',
            en: 'Velocity Penetrator',
        },
        id: 'velocityPenetrator',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [],
            },
        ],
    },
    capsuleBooster: {
        image: '',
        names: {
            ru: 'Усилитель Капсулы',
            en: 'Capsule Booster',
        },
        id: 'capsuleBooster',
        levels: [
            {
                level: 0,
                startTime: 26400,
                upgradeBoosts: {},
                requirements: {
                    food: 75900,
                    iron: 75900,
                    nuApe: 50300,
                },
                dependencies: [
                    {
                        name: 'Бомба Скорости',
                        id: 'speedBomb',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Высокоточный снайпер',
                        id: 'precisionSniper',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 42120,
                upgradeBoosts: {},
                requirements: {
                    food: 138800,
                    iron: 138800,
                    nuApe: 100700,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 52800,
                upgradeBoosts: {},
                requirements: {
                    food: 201800,
                    iron: 201800,
                    nuApe: 151000,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 79260,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 327700,
                    nuApe: 251800,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 118800,
                upgradeBoosts: {},
                requirements: {
                    food: 516600,
                    iron: 516600,
                    nuApe: 352500,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 169200,
                upgradeBoosts: {},
                requirements: {
                    food: 768400,
                    iron: 768400,
                    nuApe: 503600,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1200000,
                    nuApe: 755400,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 396000,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 1800000,
                    nuApe: 1200000,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 594000,
                upgradeBoosts: {},
                requirements: {
                    food: 2700000,
                    iron: 2700000,
                    nuApe: 2200000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 921600,
                upgradeBoosts: {},
                requirements: {
                    food: 4300000,
                    iron: 4300000,
                    nuApe: 3500000,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    enhancedRecipes: {
        image: '',
        names: {
            ru: 'Усиленные Рецепты',
            en: 'Enhanced Recipes',
        },
        id: 'enhancedRecipes',
        levels: [
            {
                level: 0,
                startTime: 52800,
                upgradeBoosts: {},
                requirements: {
                    food: 201800,
                    iron: 201800,
                    nuApe: 100700,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79260,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 327700,
                    nuApe: 201400,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 104400,
                upgradeBoosts: {},
                requirements: {
                    food: 453600,
                    iron: 453600,
                    nuApe: 302100,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 158400,
                upgradeBoosts: {},
                requirements: {
                    food: 705400,
                    iron: 705400,
                    nuApe: 503600,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 237600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1000000,
                    nuApe: 705100,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 342000,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1500000,
                    nuApe: 1000000,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 525600,
                upgradeBoosts: {},
                requirements: {
                    food: 2400000,
                    iron: 2400000,
                    nuApe: 1500000,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 7,
                    },
                ],
            },
            {
                level: 7,
                startTime: 792000,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 3700000,
                    nuApe: 2500000,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Усилитель Капсулы',
                        id: 'capsuleBooster',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    stardustBombs: {
        image: stardustBombsImg,
        names: {
            ru: 'Бомбы из звёздной пыли',
            en: 'Stardust Bombs',
        },
        id: 'stardustBombs',
        levels: [
            {
                level: 0,
                startTime: 71280,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1200000,
                    nuApe: 87500,
                },
                dependencies: [
                    {
                        name: 'Бомба Скорости',
                        id: 'speedBomb',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Высокоточный снайпер',
                        id: 'precisionSniper',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Динамо-молоток',
                        id: 'dynamoHammer',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Проникающий Скоростник',
                        id: 'velocityPenetrator',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Бомба Скорости',
                        id: 'speedBomb',
                        requiredLevel: 3,
                    },
                    {
                        name: 'Высокоточный снайпер',
                        id: 'precisionSniper',
                        requiredLevel: 3,
                    },
                    {
                        name: 'Динамо-молоток',
                        id: 'dynamoHammer',
                        requiredLevel: 3,
                    },
                    {
                        name: 'Проникающий Скоростник',
                        id: 'velocityPenetrator',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 1,
                startTime: 154800,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1800000,
                    nuApe: 113700,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 342000,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2500000,
                    nuApe: 147800,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 756000,
                upgradeBoosts: {},
                requirements: {
                    food: 3300000,
                    iron: 3600000,
                    nuApe: 320300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 1666800,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5000000,
                    nuApe: 583100,
                },
                dependencies: [
                    {
                        name: 'Бомба Скорости',
                        id: 'speedBomb',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Высокоточный снайпер',
                        id: 'precisionSniper',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Динамо-молоток',
                        id: 'dynamoHammer',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Проникающий Скоростник',
                        id: 'velocityPenetrator',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 5,
                startTime: 3672000,
                upgradeBoosts: {},
                requirements: {
                    food: 7500000,
                    iron: 7100000,
                    nuApe: 866300,
                },
                dependencies: [
                    {
                        name: 'Бомба Скорости',
                        id: 'speedBomb',
                        requiredLevel: 5,
                    },
                    {
                        name: 'Высокоточный снайпер',
                        id: 'precisionSniper',
                        requiredLevel: 5,
                    },
                    {
                        name: 'Динамо-молоток',
                        id: 'dynamoHammer',
                        requiredLevel: 5,
                    },
                    {
                        name: 'Проникающий Скоростник',
                        id: 'velocityPenetrator',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 6,
                startTime: 8078400,
                upgradeBoosts: {},
                requirements: {
                    food: 11300000,
                    iron: 9900000,
                    nuApe: 1200000,
                },
                dependencies: [],
            },
        ],
    },
    improvedEfficiency: {
        image: '',
        names: {
            ru: 'Улучшенная эффективность',
            en: 'Improved Efficiency',
        },
        id: 'improvedEfficiency',
        levels: [
            {
                level: 0,
                startTime: 26400,
                upgradeBoosts: {},
                requirements: {
                    food: 75900,
                    iron: 75900,
                    nuApe: 50300,
                },
                dependencies: [
                    {
                        name: 'Динамо-молоток',
                        id: 'dynamoHammer',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Проникающий Скоростник',
                        id: 'velocityPenetrator',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 39642,
                upgradeBoosts: {},
                requirements: {
                    food: 138800,
                    iron: 138800,
                    nuApe: 100700,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 52800,
                upgradeBoosts: {},
                requirements: {
                    food: 201800,
                    iron: 201800,
                    nuApe: 151000,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 79260,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 32700,
                    nuApe: 251800,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 118860,
                upgradeBoosts: {},
                requirements: {
                    food: 516600,
                    iron: 516600,
                    nuApe: 352500,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 169200,
                upgradeBoosts: {},
                requirements: {
                    food: 768400,
                    iron: 768400,
                    nuApe: 503600,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1200000,
                    nuApe: 755400,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 396000,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 1800000,
                    nuApe: 1200000,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 594000,
                upgradeBoosts: {},
                requirements: {
                    food: 2700000,
                    iron: 2700000,
                    nuApe: 2200000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 921600,
                upgradeBoosts: {},
                requirements: {
                    food: 4300000,
                    iron: 4300000,
                    nuApe: 3500000,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    refiningRecipes: {
        image: '',
        names: {
            ru: 'Рецепты чистки',
            en: 'Refining Recipes',
        },
        id: 'refiningRecipes',
        levels: [
            {
                level: 0,
                startTime: 52800,
                upgradeBoosts: {},
                requirements: {
                    food: 201800,
                    iron: 201800,
                    nuApe: 100700,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79260,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 327700,
                    nuApe: 201400,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 104400,
                upgradeBoosts: {},
                requirements: {
                    food: 453600,
                    iron: 453600,
                    nuApe: 302100,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 158460,
                upgradeBoosts: {},
                requirements: {
                    food: 705400,
                    iron: 705400,
                    nuApe: 503600,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 237600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1000000,
                    nuApe: 705100,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 342000,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1500000,
                    nuApe: 1000000,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 525600,
                upgradeBoosts: {},
                requirements: {
                    food: 2400000,
                    iron: 2400000,
                    nuApe: 1500000,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 7,
                    },
                ],
            },
            {
                level: 7,
                startTime: 792000,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 3700000,
                    nuApe: 2500000,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Улучшенная эффективность',
                        id: 'improvedEfficiency',
                        requiredLevel: 9,
                    },
                ],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    momentumShield: {
        image: momentumShieldImg,
        names: {
            ru: 'Щит Скорости',
            en: 'Momentum Shield',
        },
        id: 'momentumShield',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 5,
                    },
                ],
            },
        ],
    },
    securityBarrier: {
        image: securityBarrierImg,
        names: {
            ru: 'Безопасностной барьер',
            en: 'Security Barrier',
        },
        id: 'securityBarrier',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 5,
                    },
                ],
            },
        ],
    },
    apeOfSteel: {
        image: apeOfSteelImg,
        names: {
            ru: 'Обезьяна из стали',
            en: 'Ape Of Steel',
        },
        id: 'apeOfSteel',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 5,
                    },
                ],
            },
        ],
    },
    bulletproofChest: {
        image: bulletproofChestImg,
        names: {
            ru: 'Пуленепробиваемый сундук',
            en: 'Bulletproof Chest',
        },
        id: 'bulletproofChest',
        levels: [
            {
                level: 0,
                startTime: 34560,
                upgradeBoosts: {},
                requirements: {
                    food: 350000,
                    iron: 475000,
                    nuApe: 46200,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 685000,
                    nuApe: 53100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 979000,
                    nuApe: 61100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 363600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1300000,
                    nuApe: 122300,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 730800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1900000,
                    nuApe: 244600,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 5,
                    },
                ],
            },
        ],
    },
    feelingInspired: {
        image: '',
        names: {
            ru: 'Чувство Вдохновения',
            en: 'Feeling Inspired',
        },
        id: 'feelingInspired',
        levels: [
            {
                level: 0,
                startTime: 26400,
                upgradeBoosts: {},
                requirements: {
                    food: 75900,
                    iron: 75900,
                    nuApe: 50300,
                },
                dependencies: [
                    {
                        name: 'Щит Скорости',
                        id: 'momentumShield',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Безопасностной барьер',
                        id: 'securityBarrier',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Обезьяна из стали',
                        id: 'apeOfSteel',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Пуленепробиваемый сундук',
                        id: 'bulletproofChest',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 39642,
                upgradeBoosts: {},
                requirements: {
                    food: 138800,
                    iron: 138800,
                    nuApe: 100700,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 52800,
                upgradeBoosts: {},
                requirements: {
                    food: 201800,
                    iron: 201800,
                    nuApe: 151000,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 79260,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 327700,
                    nuApe: 251800,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 118800,
                upgradeBoosts: {},
                requirements: {
                    food: 516600,
                    iron: 516600,
                    nuApe: 352500,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 169200,
                upgradeBoosts: {},
                requirements: {
                    food: 768400,
                    iron: 768400,
                    nuApe: 503600,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1200000,
                    nuApe: 755400,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 396000,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 1800000,
                    nuApe: 1200000,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 594000,
                upgradeBoosts: {},
                requirements: {
                    food: 2700000,
                    iron: 2700000,
                    nuApe: 2200000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 921600,
                upgradeBoosts: {},
                requirements: {
                    food: 4300000,
                    iron: 4300000,
                    nuApe: 3500000,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    quickConstruction: {
        image: '',
        names: {
            ru: 'Быстрое строительство',
            en: 'Quick Construction',
        },
        id: 'quickConstruction',
        levels: [
            {
                level: 0,
                startTime: 52800,
                upgradeBoosts: {},
                requirements: {
                    food: 201800,
                    iron: 201800,
                    nuApe: 100700,
                },
                dependencies: [
                    {
                        name: 'Чувство Вдохновения',
                        id: 'feelingInspired',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 79440,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 327700,
                    nuApe: 201400,
                },
                dependencies: [
                    {
                        name: 'Чувство Вдохновения',
                        id: 'feelingInspired',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 104400,
                upgradeBoosts: {},
                requirements: {
                    food: 453600,
                    iron: 453600,
                    nuApe: 302100,
                },
                dependencies: [
                    {
                        name: 'Чувство Вдохновения',
                        id: 'feelingInspired',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 158400,
                upgradeBoosts: {},
                requirements: {
                    food: 705400,
                    iron: 705400,
                    nuApe: 503600,
                },
                dependencies: [
                    {
                        name: 'Чувство Вдохновения',
                        id: 'feelingInspired',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 237600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1000000,
                    nuApe: 705100,
                },
                dependencies: [
                    {
                        name: 'Чувство Вдохновения',
                        id: 'feelingInspired',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 342000,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1500000,
                    nuApe: 1000000,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 525600,
                upgradeBoosts: {},
                requirements: {
                    food: 2400000,
                    iron: 2400000,
                    nuApe: 1500000,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 792000,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 3700000,
                    nuApe: 2500000,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 1188000,
                upgradeBoosts: {},
                requirements: {
                    food: 5600000,
                    iron: 5600000,
                    nuApe: 4500000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    masterArchitect: {
        image: '',
        names: {
            ru: 'Мастер-архитектор',
            en: 'Master Architect',
        },
        id: 'masterArchitect',
        levels: [
            {
                level: 0,
                startTime: 79260,
                upgradeBoosts: {},
                requirements: {
                    food: 327700,
                    iron: 327700,
                    nuApe: 151000,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 118800,
                upgradeBoosts: {},
                requirements: {
                    food: 516600,
                    iron: 516600,
                    nuApe: 302100,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 158400,
                upgradeBoosts: {},
                requirements: {
                    food: 705400,
                    iron: 705400,
                    nuApe: 453200,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 237600,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1000000,
                    nuApe: 755400,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 356400,
                upgradeBoosts: {},
                requirements: {
                    food: 1600000,
                    iron: 1600000,
                    nuApe: 1000000,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 514800,
                upgradeBoosts: {},
                requirements: {
                    food: 2400000,
                    iron: 2400000,
                    nuApe: 1500000,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 792000,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 3700000,
                    nuApe: 2200000,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 7,
                    },
                ],
            },
            {
                level: 7,
                startTime: 1188000,
                upgradeBoosts: {},
                requirements: {
                    food: 5600000,
                    iron: 5600000,
                    nuApe: 3700000,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Быстрое строительство',
                        id: 'quickConstruction',
                        requiredLevel: 9,
                    },
                ],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    researchMania: {
        image: '',
        names: {
            ru: 'Мания исследований',
            en: 'Research Mania',
        },
        id: 'researchMania',
        levels: [
            {
                level: 0,
                startTime: 3960,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 15200,
                },
                dependencies: [
                    {
                        name: 'Мастер-архитектор',
                        id: 'masterArchitect',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 36035,
                upgradeBoosts: {},
                requirements: {
                    food: 121600,
                    iron: 121600,
                    nuApe: 137300,
                },
                dependencies: [
                    {
                        name: 'Мастер-архитектор',
                        id: 'masterArchitect',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 54720,
                upgradeBoosts: {},
                requirements: {
                    food: 210700,
                    iron: 210700,
                    nuApe: 208500,
                },
                dependencies: [
                    {
                        name: 'Мастер-архитектор',
                        id: 'masterArchitect',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 73380,
                upgradeBoosts: {},
                requirements: {
                    food: 299700,
                    iron: 299700,
                    nuApe: 279700,
                },
                dependencies: [
                    {
                        name: 'Мастер-архитектор',
                        id: 'masterArchitect',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 90000,
                upgradeBoosts: {},
                requirements: {
                    food: 382300,
                    iron: 382300,
                    nuApe: 345900,
                },
                dependencies: [
                    {
                        name: 'Мастер-архитектор',
                        id: 'masterArchitect',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 126000,
                upgradeBoosts: {},
                requirements: {
                    food: 560400,
                    iron: 560400,
                    nuApe: 488300,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 732100,
                    iron: 732100,
                    nuApe: 625600,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 821100,
                    iron: 821100,
                    nuApe: 696900,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 453600,
                upgradeBoosts: {},
                requirements: {
                    food: 2100000,
                    iron: 2100000,
                    nuApe: 1700000,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 471600,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2200000,
                    nuApe: 1800000,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 489600,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2200000,
                    nuApe: 1800000,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 507600,
                upgradeBoosts: {},
                requirements: {
                    food: 2300000,
                    iron: 2300000,
                    nuApe: 1900000,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 525600,
                upgradeBoosts: {},
                requirements: {
                    food: 2400000,
                    iron: 2400000,
                    nuApe: 2000000,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 543600,
                upgradeBoosts: {},
                requirements: {
                    food: 2500000,
                    iron: 2500000,
                    nuApe: 2000000,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Лаборатория',
                        id: 'researchLab',
                        requiredLevel: 33,
                    },
                ],
            },
        ],
    },
    unitedWeStand: {
        image: unitedWeStandImg,
        names: {
            ru: 'Вместе мы сила',
            en: 'United We Stand',
        },
        id: 'unitedWeStand',
        levels: [
            {
                level: 0,
                startTime: 74520,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1200000,
                    nuApe: 291600,
                },
                dependencies: [
                    {
                        name: 'Щит Скорости',
                        id: 'momentumShield',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Безопасностной барьер',
                        id: 'securityBarrier',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Обезьяна из стали',
                        id: 'apeOfSteel',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Пуленепробиваемый сундук',
                        id: 'bulletproofChest',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Щит Скорости',
                        id: 'momentumShield',
                        requiredLevel: 3,
                    },
                    {
                        name: 'Безопасностной барьер',
                        id: 'securityBarrier',
                        requiredLevel: 3,
                    },
                    {
                        name: 'Обезьяна из стали',
                        id: 'apeOfSteel',
                        requiredLevel: 3,
                    },
                    {
                        name: 'Пуленепробиваемый сундук',
                        id: 'bulletproofChest',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 1,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1800000,
                    nuApe: 379100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 360000,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2500000,
                    nuApe: 492900,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 792000,
                upgradeBoosts: {},
                requirements: {
                    food: 3300000,
                    iron: 3600000,
                    nuApe: 640700,
                },
                dependencies: [
                    {
                        name: 'Щит Скорости',
                        id: 'momentumShield',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Безопасностной барьер',
                        id: 'securityBarrier',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Обезьяна из стали',
                        id: 'apeOfSteel',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Пуленепробиваемый сундук',
                        id: 'bulletproofChest',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 1742400,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5000000,
                    nuApe: 833000,
                },
                dependencies: [
                    {
                        name: 'Щит Скорости',
                        id: 'momentumShield',
                        requiredLevel: 5,
                    },
                    {
                        name: 'Безопасностной барьер',
                        id: 'securityBarrier',
                        requiredLevel: 5,
                    },
                    {
                        name: 'Обезьяна из стали',
                        id: 'apeOfSteel',
                        requiredLevel: 5,
                    },
                    {
                        name: 'Пуленепробиваемый сундук',
                        id: 'bulletproofChest',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 3837600,
                upgradeBoosts: {},
                requirements: {
                    food: 75000000,
                    iron: 7100000,
                    nuApe: 1000000,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 7347000,
                upgradeBoosts: {},
                requirements: {
                    food: 11300000,
                    iron: 9900000,
                    nuApe: 1400000,
                },
                dependencies: [
                    {
                        name: 'Бомбы из звёздной пыли',
                        id: 'stardustBombs',
                        requiredLevel: 7,
                    },
                ],
            },
            // {
            //     level: 7,
            //     startTime: 0,
            //     upgradeBoosts: {},
            //     requirements: {
            //         food: 0,
            //         iron: 0,
            //         nuApe: 0,
            //     },
            //     dependencies: [
            //         {
            //             name: 'Бомбы из звёздной пыли',
            //             id: 'stardustBombs',
            //             requiredLevel: 8,
            //         },
            //     ],
            // },
        ],
    },
    interstellarTravel: {
        image: interstellarTravelImg,
        names: {
            ru: 'Межзвёздное путешествие',
            en: 'Interstellar Travel',
        },
        id: 'interstellarTravel',
        levels: [
            {
                level: 0,
                startTime: 64800,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1200000,
                    nuApe: 291600,
                },
                dependencies: [
                    {
                        name: 'Вместе мы сила',
                        id: 'unitedWeStand',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Вместе мы сила',
                        id: 'unitedWeStand',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 1,
                startTime: 140400,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1800000,
                    nuApe: 379100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 313200,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2500000,
                    nuApe: 492900,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 687600,
                upgradeBoosts: {},
                requirements: {
                    food: 3300000,
                    iron: 3600000,
                    nuApe: 640700,
                },
                dependencies: [
                    {
                        name: 'Вместе мы сила',
                        id: 'unitedWeStand',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 1515600,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5000000,
                    nuApe: 833000,
                },
                dependencies: [
                    {
                        name: 'Вместе мы сила',
                        id: 'unitedWeStand',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 3337200,
                upgradeBoosts: {},
                requirements: {
                    food: 7500000,
                    iron: 7100000,
                    nuApe: 1000000,
                },
                dependencies: [
                    {
                        name: 'Вместе мы сила',
                        id: 'unitedWeStand',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 7524000,
                upgradeBoosts: {},
                requirements: {
                    food: 11300000,
                    iron: 9900000,
                    nuApe: 1400000,
                },
                dependencies: [
                    {
                        name: 'Вместе мы сила',
                        id: 'unitedWeStand',
                        requiredLevel: 7,
                    },
                ],
            },
            // {
            //     level: 7,
            //     startTime: 0,
            //     upgradeBoosts: {},
            //     requirements: {
            //         food: 0,
            //         iron: 0,
            //         nuApe: 0,
            //     },
            //     dependencies: [
            //         {
            //             name: 'Вместе мы сила',
            //             id: 'unitedWeStand',
            //             requiredLevel: 8,
            //         },
            //     ],
            // },
        ],
    },
    superWeapons: {
        image: '',
        names: {
            ru: 'Супероружие',
            en: 'Super WeaponsPage',
        },
        id: 'superWeapons',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Щит Скорости',
                        id: 'momentumShield',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Безопасностной барьер',
                        id: 'securityBarrier',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Обезьяна из стали',
                        id: 'apeOfSteel',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Пуленепробиваемый сундук',
                        id: 'bulletproofChest',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    armoredMechs: {
        image: '',
        names: {
            ru: 'Бронированные Мехи',
            en: 'Armored Mechs',
        },
        id: 'armoredMechs',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Супероружие',
                        id: 'superWeapons',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    mechEnergizer: {
        image: '',
        names: {
            ru: 'Стимулятор Меха',
            en: 'Mech Energizer',
        },
        id: 'mechEnergizer',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Бронированные Мехи',
                        id: 'armoredMechs',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    mechRising: {
        image: '',
        names: {
            ru: 'Восстание Мехов',
            en: 'Mech Rising',
        },
        id: 'mechRising',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Стимулятор Меха',
                        id: 'mechEnergizer',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    spaceWarrior: {
        image: spaceWarriorImg,
        names: {
            ru: 'Космический воин',
            en: 'Space Warrior',
        },
        id: 'spaceWarrior',
        levels: [
            {
                level: 0,
                startTime: 90000,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1200000,
                    nuApe: 291600,
                },
                dependencies: [
                    {
                        name: 'Межзвёздное путешествие',
                        id: 'interstellarTravel',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Межзвёздное путешествие',
                        id: 'interstellarTravel',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 1,
                startTime: 198000,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1800000,
                    nuApe: 379100,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 435600,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2500000,
                    nuApe: 492900,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 964800,
                upgradeBoosts: {},
                requirements: {
                    food: 3300000,
                    iron: 3600000,
                    nuApe: 640700,
                },
                dependencies: [
                    {
                        name: 'Межзвёздное путешествие',
                        id: 'interstellarTravel',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 2124000,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5000000,
                    nuApe: 833000,
                },
                dependencies: [
                    {
                        name: 'Межзвёздное путешествие',
                        id: 'interstellarTravel',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 4672800,
                upgradeBoosts: {},
                requirements: {
                    food: 7500000,
                    iron: 7100000,
                    nuApe: 1000000,
                },
                dependencies: [
                    {
                        name: 'Межзвёздное путешествие',
                        id: 'interstellarTravel',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 10285200,
                upgradeBoosts: {},
                requirements: {
                    food: 11300000,
                    iron: 9900000,
                    nuApe: 1400000,
                },
                dependencies: [
                    {
                        name: 'Межзвёздное путешествие',
                        id: 'interstellarTravel',
                        requiredLevel: 7,
                    },
                ],
            },
            // {
            //     level: 7,
            //     startTime: 0,
            //     upgradeBoosts: {},
            //     requirements: {
            //         food: 0,
            //         iron: 0,
            //         nuApe: 0,
            //     },
            //     dependencies: [
            //         {
            //             name: 'Межзвёздное путешествие',
            //             id: 'interstellarTravel',
            //             requiredLevel: 8,
            //         },
            //     ],
            // },
        ],
    },
    timeToSoar: {
        image: timeToSoarImg,
        names: {
            ru: 'Время взлететь',
            en: 'Time To Soar',
        },
        id: 'timeToSoar',
        levels: [
            {
                level: 0,
                startTime: 65280,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 850000,
                    nuApe: 195000,
                },
                dependencies: [
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 7,
                    },
                    {
                        name: 'Гонщик V',
                        id: 'pilots5',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 1200000,
                    nuApe: 253500,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 406800,
                upgradeBoosts: {},
                requirements: {
                    food: 1300000,
                    iron: 1700000,
                    nuApe: 329500,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 1018800,
                upgradeBoosts: {},
                requirements: {
                    food: 1900000,
                    iron: 2400000,
                    nuApe: 428400,
                },
                dependencies: [],
            },
        ],
    },
    hurricaneBullets: {
        image: hurricaneBulletsImg,
        names: {
            ru: 'Пули "Ураган"',
            en: 'Hurricane Bullets',
        },
        id: 'hurricaneBullets',
        levels: [
            {
                level: 0,
                startTime: 65280,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 850000,
                    nuApe: 195000,
                },
                dependencies: [
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 7,
                    },
                    {
                        name: 'Стрелки V',
                        id: 'shooters5',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 1200000,
                    nuApe: 253500,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 406800,
                upgradeBoosts: {},
                requirements: {
                    food: 1300000,
                    iron: 1700000,
                    nuApe: 329500,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 1018800,
                upgradeBoosts: {},
                requirements: {
                    food: 1900000,
                    iron: 2400000,
                    nuApe: 428400,
                },
                dependencies: [],
            },
        ],
    },
    forceOfSpeed: {
        image: forceOfSpeedImg,
        names: {
            ru: 'Сила Скорости',
            en: 'Force of Speed',
        },
        id: 'forceOfSpeed',
        levels: [
            {
                level: 0,
                startTime: 65280,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 850000,
                    nuApe: 195000,
                },
                dependencies: [
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 7,
                    },
                    {
                        name: 'Громилы V',
                        id: 'hitters5',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 1200000,
                    nuApe: 253500,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 406800,
                upgradeBoosts: {},
                requirements: {
                    food: 1300000,
                    iron: 1700000,
                    nuApe: 329500,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 1018800,
                upgradeBoosts: {},
                requirements: {
                    food: 1900000,
                    iron: 2400000,
                    nuApe: 428400,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 2548800,
                upgradeBoosts: {},
                requirements: {
                    food: 2900000,
                    iron: 3400000,
                    nuApe: 556900,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 5101200,
                upgradeBoosts: {},
                requirements: {
                    food: 4500000,
                    iron: 4700000,
                    nuApe: 724000,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 7653600,
                upgradeBoosts: {},
                requirements: {
                    food: 6700000,
                    iron: 6700000,
                    nuApe: 941200,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 11480400,
                upgradeBoosts: {},
                requirements: {
                    food: 6900000,
                    iron: 6900000,
                    nuApe: 969400,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 17222400,
                upgradeBoosts: {},
                requirements: {
                    food: 7200000,
                    iron: 7100000,
                    nuApe: 998500,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 25833900,
                upgradeBoosts: {},
                requirements: {
                    food: 7400000,
                    iron: 7300000,
                    nuApe: 1000000,
                },
                dependencies: [],
            },
        ],
    },
    chariotAssault: {
        image: chariotAssaultImg,
        names: {
            ru: 'Натиск колесницы',
            en: 'Chariot Assault',
        },
        id: 'chariotAssault',
        levels: [
            {
                level: 0,
                startTime: 65280,
                upgradeBoosts: {},
                requirements: {
                    food: 550000,
                    iron: 850000,
                    nuApe: 195000,
                },
                dependencies: [
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Космический воин',
                        id: 'spaceWarrior',
                        requiredLevel: 7,
                    },
                    {
                        name: 'Стеноломы V',
                        id: 'wallBreakers5',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 850000,
                    iron: 1200000,
                    nuApe: 253500,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 406800,
                upgradeBoosts: {},
                requirements: {
                    food: 1300000,
                    iron: 1700000,
                    nuApe: 329500,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 1018800,
                upgradeBoosts: {},
                requirements: {
                    food: 1900000,
                    iron: 2400000,
                    nuApe: 428400,
                },
                dependencies: [],
            },
        ],
    },
    blastCores: {
        image: blastCoresImg,
        names: {
            ru: 'Взырвные ядра',
            en: 'Blast Cores',
        },
        id: 'blastCores',
        levels: [
            {
                level: 0,
                startTime: 46620,
                upgradeBoosts: {},
                requirements: {
                    food: 750000,
                    iron: 1000000,
                    nuApe: 316200,
                },
                dependencies: [
                    {
                        name: 'Время взлететь',
                        id: 'timeToSoar',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 115200,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1400000,
                    nuApe: 505900,
                },
                dependencies: [
                    {
                        name: 'Время взлететь',
                        id: 'timeToSoar',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 2000000,
                    nuApe: 809400,
                },
                dependencies: [
                    {
                        name: 'Время взлететь',
                        id: 'timeToSoar',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 727200,
                upgradeBoosts: {},
                requirements: {
                    food: 2600000,
                    iron: 2800000,
                    nuApe: 1200000,
                },
                dependencies: [
                    {
                        name: 'Время взлететь',
                        id: 'timeToSoar',
                        requiredLevel: 4,
                    },
                ],
            },
            // {
            //     level: 4,
            //     startTime: 0,
            //     upgradeBoosts: {},
            //     requirements: {
            //         food: 0,
            //         iron: 0,
            //         nuApe: 0,
            //     },
            //     dependencies: [
            //         {
            //             name: 'Время взлететь',
            //             id: 'timeToSoar',
            //             requiredLevel: 5,
            //         },
            //     ],
            // },
        ],
    },
    pulseSniper: {
        image: pulseSniperImg,
        names: {
            ru: 'Снайпер Пульс',
            en: 'Pulse Sniper',
        },
        id: 'pulseSniper',
        levels: [
            {
                level: 0,
                startTime: 46620,
                upgradeBoosts: {},
                requirements: {
                    food: 750000,
                    iron: 1000000,
                    nuApe: 316200,
                },
                dependencies: [
                    {
                        name: 'Пули "Ураган"',
                        id: 'hurricaneBullets',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 115200,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1400000,
                    nuApe: 505900,
                },
                dependencies: [
                    {
                        name: 'Пули "Ураган"',
                        id: 'hurricaneBullets',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 2000000,
                    nuApe: 809400,
                },
                dependencies: [
                    {
                        name: 'Пули "Ураган"',
                        id: 'hurricaneBullets',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 727200,
                upgradeBoosts: {},
                requirements: {
                    food: 2600000,
                    iron: 2800000,
                    nuApe: 1200000,
                },
                dependencies: [
                    {
                        name: 'Пули "Ураган"',
                        id: 'hurricaneBullets',
                        requiredLevel: 4,
                    },
                ],
            },
            // {
            //     level: 4,
            //     startTime: 0,
            //     upgradeBoosts: {},
            //     requirements: {
            //         food: 0,
            //         iron: 0,
            //         nuApe: 0,
            //     },
            //     dependencies: [
            //         {
            //             name: 'Пули "Ураган"',
            //             id: 'hurricaneBullets',
            //             requiredLevel: 5,
            //         },
            //     ],
            // },
        ],
    },
    hiEnergyHammer: {
        image: hiEnergyHammerImg,
        names: {
            ru: 'Молоток Высокой Энергии',
            en: 'Hi-Energy Hammer',
        },
        id: 'hiEnergyHammer',
        levels: [
            {
                level: 0,
                startTime: 46620,
                upgradeBoosts: {},
                requirements: {
                    food: 750000,
                    iron: 1000000,
                    nuApe: 316200,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 115200,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1400000,
                    nuApe: 505900,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 2000000,
                    nuApe: 809400,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 727200,
                upgradeBoosts: {},
                requirements: {
                    food: 2600000,
                    iron: 2800000,
                    nuApe: 1200000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 1458000,
                upgradeBoosts: {},
                requirements: {
                    food: 4000000,
                    iron: 4000000,
                    nuApe: 2000000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 2916000,
                upgradeBoosts: {},
                requirements: {
                    food: 6000000,
                    iron: 5600000,
                    nuApe: 3300000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 5832000,
                upgradeBoosts: {},
                requirements: {
                    food: 9000000,
                    iron: 7900000,
                    nuApe: 5300000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 7,
                    },
                ],
            },
            {
                level: 7,
                startTime: 8748000,
                upgradeBoosts: {},
                requirements: {
                    food: 9300000,
                    iron: 8100000,
                    nuApe: 7400000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 8,
                startTime: 13122000,
                upgradeBoosts: {},
                requirements: {
                    food: 9600000,
                    iron: 8400000,
                    nuApe: 10300000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 9,
                    },
                ],
            },
            {
                level: 9,
                startTime: 19681200,
                upgradeBoosts: {},
                requirements: {
                    food: 9900000,
                    iron: 8600000,
                    nuApe: 14500000,
                },
                dependencies: [
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 10,
                    },
                ],
            },
        ],
    },
    heavyExplosives: {
        image: heavyExplosivesImg,
        names: {
            ru: 'Тяжёлые взрывчатки',
            en: 'Heavy Explosives',
        },
        id: 'heavyExplosives',
        levels: [
            {
                level: 0,
                startTime: 46620,
                upgradeBoosts: {},
                requirements: {
                    food: 750000,
                    iron: 1000000,
                    nuApe: 316200,
                },
                dependencies: [
                    {
                        name: 'Натиск колесницы',
                        id: 'chariotAssault',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 115200,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1400000,
                    nuApe: 505900,
                },
                dependencies: [
                    {
                        name: 'Натиск колесницы',
                        id: 'chariotAssault',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 2000000,
                    nuApe: 809400,
                },
                dependencies: [
                    {
                        name: 'Натиск колесницы',
                        id: 'chariotAssault',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 727200,
                upgradeBoosts: {},
                requirements: {
                    food: 2600000,
                    iron: 2800000,
                    nuApe: 1200000,
                },
                dependencies: [
                    {
                        name: 'Натиск колесницы',
                        id: 'chariotAssault',
                        requiredLevel: 4,
                    },
                ],
            },
            // {
            //     level: 4,
            //     startTime: 0,
            //     upgradeBoosts: {},
            //     requirements: {
            //         food: 0,
            //         iron: 0,
            //         nuApe: 0,
            //     },
            //     dependencies: [
            //         {
            //             name: 'Натиск колесницы',
            //             id: 'chariotAssault',
            //             requiredLevel: 5,
            //         },
            //     ],
            // },
        ],
    },
    spaceWalk: {
        image: spaceWalkImg,
        names: {
            ru: 'Прогулка по космосу',
            en: 'Space Walk',
        },
        id: 'spaceWalk',
        levels: [
            {
                level: 0,
                startTime: 81000,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1200000,
                    nuApe: 326600,
                },
                dependencies: [
                    {
                        name: 'Взырвные ядра',
                        id: 'blastCores',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Снайпер Пульс',
                        id: 'pulseSniper',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Молоток Высокой Энергии',
                        id: 'hiEnergyHammer',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Тяжёлые взрывчатки',
                        id: 'heavyExplosives',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Взырвные ядра',
                        id: 'blastCores',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Снайпер Пульс',
                        id: 'pulseSniper',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Молоток Высокой Энергии',
                        id: 'hiEnergyHammer',
                        requiredLevel: 4,
                    },
                    {
                        name: 'Тяжёлые взрывчатки',
                        id: 'heavyExplosives',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 1,
                startTime: 201600,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1800000,
                    nuApe: 424600,
                },
                dependencies: [
                    {
                        name: 'Взырвные ядра',
                        id: 'blastCores',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 2,
                startTime: 504000,
                upgradeBoosts: {},
                requirements: {
                    food: 2200000,
                    iron: 2500000,
                    nuApe: 552000,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 1263600,
                upgradeBoosts: {},
                requirements: {
                    food: 3300000,
                    iron: 3600000,
                    nuApe: 717600,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 2782800,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5000000,
                    nuApe: 932900,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 6123600,
                upgradeBoosts: {},
                requirements: {
                    food: 7500000,
                    iron: 7100000,
                    nuApe: 1200000,
                },
                dependencies: [],
            },
        ],
    },
    steelriders: {
        image: '',
        names: {
            ru: 'Стальные всадники',
            en: 'Steelriders',
        },
        id: 'steelriders',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Гонщики V',
                        id: 'pilots5',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Взырвные ядра',
                        id: 'blastCores',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Время взлететь',
                        id: 'timeToSoar',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Лаборатория',
                        id: 'researchLab',
                        requiredLevel: 35,
                    },
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 6,
                    },
                    {
                        name: 'Взырвные ядра',
                        id: 'blastCores',
                        requiredLevel: 10,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    skyrenders: {
        image: '',
        names: {
            ru: 'Небесные Разрушители',
            en: 'Skyrenders',
        },
        id: 'skyrenders',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Стрелки V',
                        id: 'shooters5',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Снайпер Пульс',
                        id: 'pulseSniper',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Пули "Ураган"',
                        id: 'hurricaneBullets',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Лаборатория',
                        id: 'researchLab',
                        requiredLevel: 35,
                    },
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 6,
                    },
                    {
                        name: 'Снайпер Пульс',
                        id: 'pulseSniper',
                        requiredLevel: 10,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    earthshakers: {
        image: earthshakersImg,
        names: {
            ru: 'Трясущие Землю',
            en: 'Earthshakers',
        },
        id: 'earthshakers',
        levels: [
            {
                level: 0,
                startTime: 10893600,
                upgradeBoosts: {},
                requirements: {
                    food: 15200000,
                    iron: 15200000,
                    nuApe: 3800000,
                },
                dependencies: [
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Громилы V',
                        id: 'hitters5',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Молоток Высокой Энергии',
                        id: 'hiEnergyHammer',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Сила Скорости',
                        id: 'forceOfSpeed',
                        requiredLevel: 1,
                    },

                    {
                        name: 'Лаборатория',
                        id: 'researchLab',
                        requiredLevel: 35,
                    },
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 6,
                    },
                    {
                        name: 'Молоток Высокой Энергии',
                        id: 'hiEnergyHammer',
                        requiredLevel: 10,
                    },
                ],
            },
        ],
    },
    infinityTanks: {
        image: '',
        names: {
            ru: 'Бесконечные танки',
            en: 'Infinity Tanks',
        },
        id: 'infinityTanks',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Стеноломы V',
                        id: 'wallBreakers5',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Тяжёлые взрывчатки',
                        id: 'heavyExplosives',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Натиск колесницы',
                        id: 'chariotAssault',
                        requiredLevel: 1,
                    },

                    {
                        name: 'Лаборатория',
                        id: 'researchLab',
                        requiredLevel: 35,
                    },
                    {
                        name: 'Прогулка по космосу',
                        id: 'spaceWalk',
                        requiredLevel: 6,
                    },
                    {
                        name: 'Тяжёлые взрывчатки',
                        id: 'heavyExplosives',
                        requiredLevel: 10,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    totalDomination: {
        image: '',
        names: {
            ru: 'Полное Превосходство',
            en: 'Total Domination',
        },
        id: 'totalDomination',
        levels: [
            {
                level: 0,
                startTime: 69960,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1100000,
                    nuApe: 365500,
                },
                dependencies: [ // одно из 4-х должно быть открыто
                    {
                        name: 'Стальные всадники',
                        id: 'steelriders',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Небесные Разрушители',
                        id: 'skyrenders',
                        requiredLevel: 1,
                    },
                    {
                        name: 'Трясущие Землю',
                        id: 'earthshakers',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 76980,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1200000,
                    nuApe: 376400,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 84660,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1300000,
                    nuApe: 387700,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 90000,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1400000,
                    nuApe: 399300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 100800,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1600000,
                    nuApe: 411300,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 111600,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1800000,
                    nuApe: 423700,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 122400,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 1900000,
                    nuApe: 436400,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 133200,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 2100000,
                    nuApe: 449500,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 147600,
                upgradeBoosts: {},
                requirements: {
                    food: 2000000,
                    iron: 2400000,
                    nuApe: 463000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 2300000,
                    iron: 2600000,
                    nuApe: 476800,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 2500000,
                    iron: 2900000,
                    nuApe: 491200,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 198000,
                upgradeBoosts: {},
                requirements: {
                    food: 2800000,
                    iron: 3200000,
                    nuApe: 505900,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 219600,
                upgradeBoosts: {},
                requirements: {
                    food: 3000000,
                    iron: 3500000,
                    nuApe: 521100,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 241200,
                upgradeBoosts: {},
                requirements: {
                    food: 3400000,
                    iron: 3900000,
                    nuApe: 536700,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 4300000,
                    nuApe: 552800,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    trueAdvantage: {
        image: '',
        names: {
            ru: 'Истинное преимущество',
            en: 'True Advantage',
        },
        id: 'trueAdvantage',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Полное Превосходство',
                        id: 'totalDomination',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    breakoutWarrior: {
        image: '',
        names: {
            ru: 'Воин Прорыва',
            en: 'Breakout Warrior',
        },
        id: 'breakoutWarrior',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Полное Превосходство',
                        id: 'totalDomination',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    pathOfTheChampion: {
        image: '',
        names: {
            ru: 'Путь Чемпиона',
            en: 'Path Of The Champion',
        },
        id: 'pathOfTheChampion',
        levels: [
            {
                level: 0,
                startTime: 69960,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1100000,
                    nuApe: 365500,
                },
                dependencies: [
                    {
                        name: 'Полное Превосходство',
                        id: 'totalDomination',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 76980,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1200000,
                    nuApe: 376400,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 84660,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1300000,
                    nuApe: 387700,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 90000,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1400000,
                    nuApe: 399300,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 100800,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1600000,
                    nuApe: 411300,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 111600,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1800000,
                    nuApe: 423700,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 122400,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 1900000,
                    nuApe: 436400,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 133200,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 2100000,
                    nuApe: 449500,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 147600,
                upgradeBoosts: {},
                requirements: {
                    food: 2000000,
                    iron: 2400000,
                    nuApe: 463000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 2300000,
                    iron: 2600000,
                    nuApe: 476800,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 2500000,
                    iron: 2900000,
                    nuApe: 491200,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 198000,
                upgradeBoosts: {},
                requirements: {
                    food: 2800000,
                    iron: 3200000,
                    nuApe: 505900,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 219600,
                upgradeBoosts: {},
                requirements: {
                    food: 3000000,
                    iron: 3500000,
                    nuApe: 521100,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 241200,
                upgradeBoosts: {},
                requirements: {
                    food: 3400000,
                    iron: 3900000,
                    nuApe: 536700,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 4300000,
                    nuApe: 552800,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 4100000,
                    iron: 4700000,
                    nuApe: 569400,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 320400,
                upgradeBoosts: {},
                requirements: {
                    food: 4500000,
                    iron: 5200000,
                    nuApe: 586500,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 352800,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5700000,
                    nuApe: 604100,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 388800,
                upgradeBoosts: {},
                requirements: {
                    food: 5500000,
                    iron: 6300000,
                    nuApe: 622200,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 424800,
                upgradeBoosts: {},
                requirements: {
                    food: 6000000,
                    iron: 6900000,
                    nuApe: 640900,
                },
                dependencies: [],
            },
        ],
    },
    divideAndConquer: {
        image: '',
        names: {
            ru: 'Разделяй и властвуй',
            en: 'Divide And Conquer',
        },
        id: 'divideAndConquer',
        levels: [
            {
                level: 0,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 2500000,
                    iron: 2900000,
                    nuApe: 491200,
                },
                dependencies: [
                    {
                        name: 'Полное Превосходство',
                        id: 'totalDomination',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 198000,
                upgradeBoosts: {},
                requirements: {
                    food: 2800000,
                    iron: 3200000,
                    nuApe: 505900,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 219600,
                upgradeBoosts: {},
                requirements: {
                    food: 3000000,
                    iron: 3500000,
                    nuApe: 521100,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 241200,
                upgradeBoosts: {},
                requirements: {
                    food: 3400000,
                    iron: 3900000,
                    nuApe: 536700,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 4300000,
                    nuApe: 552800,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 4100000,
                    iron: 4700000,
                    nuApe: 569400,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 320400,
                upgradeBoosts: {},
                requirements: {
                    food: 4500000,
                    iron: 5200000,
                    nuApe: 586500,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 352800,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5700000,
                    nuApe: 604100,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 388800,
                upgradeBoosts: {},
                requirements: {
                    food: 5500000,
                    iron: 6300000,
                    nuApe: 622200,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 424800,
                upgradeBoosts: {},
                requirements: {
                    food: 6000000,
                    iron: 6900000,
                    nuApe: 640900,
                },
                dependencies: [],
            },
        ],
    },
    polymerHelmet: {
        image: '',
        names: {
            ru: 'Полимерный шлем',
            en: 'Polymer Helmet',
        },
        id: 'polymerHelmet',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Стальные всадники',
                        id: 'steelriders',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    polymerArmor: {
        image: '',
        names: {
            ru: 'Полимерная броня',
            en: 'Polymer Armor',
        },
        id: 'polymerArmor',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Небесные Разрушители',
                        id: 'skyrenders',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    polymerVest: {
        image: '',
        names: {
            ru: 'Полимерный жилет',
            en: 'Polymer Vest',
        },
        id: 'polymerVest',
        levels: [
            {
                level: 0,
                startTime: 46620,
                upgradeBoosts: {},
                requirements: {
                    food: 750000,
                    iron: 1000000,
                    nuApe: 316200,
                },
                dependencies: [
                    {
                        name: 'Трясущие Землю',
                        id: 'earthshakers',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 69960,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1400000,
                    nuApe: 505900,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 104400,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 2000000,
                    nuApe: 809400,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 154800,
                upgradeBoosts: {},
                requirements: {
                    food: 2600000,
                    iron: 2800000,
                    nuApe: 1200000,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 234000,
                upgradeBoosts: {},
                requirements: {
                    food: 4000000,
                    iron: 4000000,
                    nuApe: 2000000,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 352800,
                upgradeBoosts: {},
                requirements: {
                    food: 6000000,
                    iron: 5600000,
                    nuApe: 3300000,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 529200,
                upgradeBoosts: {},
                requirements: {
                    food: 9000000,
                    iron: 7900000,
                    nuApe: 5300000,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 547200,
                upgradeBoosts: {},
                requirements: {
                    food: 9300000,
                    iron: 8100000,
                    nuApe: 5400000,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 561600,
                upgradeBoosts: {},
                requirements: {
                    food: 9600000,
                    iron: 8400000,
                    nuApe: 5600000,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 579600,
                upgradeBoosts: {},
                requirements: {
                    food: 9900000,
                    iron: 8600000,
                    nuApe: 5700000,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    titaniumGuard: {
        image: '',
        names: {
            ru: 'Титановый страж',
            en: 'Titanium Guard',
        },
        id: 'titaniumGuard',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Бесконечные танки',
                        id: 'infinityTanks',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    kineticEngine: {
        image: '',
        names: {
            ru: 'Кинетический двигатель',
            en: 'Kinetic Engine',
        },
        id: 'kineticEngine',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Полимерный шлем',
                        id: 'polymerHelmet',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    lifeArmor: {
        image: '',
        names: {
            ru: 'Броня Жизни',
            en: 'Life Armor',
        },
        id: 'lifeArmor',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Полимерная броня',
                        id: 'polymerArmor',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    ironWill: {
        image: '',
        names: {
            ru: 'Железная воля',
            en: 'Iron Will',
        },
        id: 'ironWill',
        levels: [
            {
                level: 0,
                startTime: 69960,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1100000,
                    nuApe: 365500,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 76980,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1200000,
                    nuApe: 376400,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 84660,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1300000,
                    nuApe: 387700,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 90000,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1400000,
                    nuApe: 399300,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 100800,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1600000,
                    nuApe: 411300,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 111600,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1800000,
                    nuApe: 423700,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 122400,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 1900000,
                    nuApe: 436400,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 7,
                    },
                ],
            },
            {
                level: 7,
                startTime: 133200,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 2100000,
                    nuApe: 449500,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 8,
                startTime: 147600,
                upgradeBoosts: {},
                requirements: {
                    food: 2000000,
                    iron: 2400000,
                    nuApe: 463000,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 9,
                    },
                ],
            },
            {
                level: 9,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 2300000,
                    iron: 2600000,
                    nuApe: 476800,
                },
                dependencies: [
                    {
                        name: 'Полимерный жилет',
                        id: 'polymerVest',
                        requiredLevel: 10,
                    },
                ],
            },
            {
                level: 10,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 2500000,
                    iron: 2900000,
                    nuApe: 491200,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 198000,
                upgradeBoosts: {},
                requirements: {
                    food: 2800000,
                    iron: 3200000,
                    nuApe: 505900,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 219600,
                upgradeBoosts: {},
                requirements: {
                    food: 3000000,
                    iron: 3500000,
                    nuApe: 521100,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 241200,
                upgradeBoosts: {},
                requirements: {
                    food: 3400000,
                    iron: 3900000,
                    nuApe: 536700,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 4300000,
                    nuApe: 552800,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 4100000,
                    iron: 4700000,
                    nuApe: 569400,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 320400,
                upgradeBoosts: {},
                requirements: {
                    food: 4500000,
                    iron: 5200000,
                    nuApe: 586500,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 352800,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5700000,
                    nuApe: 604100,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 388800,
                upgradeBoosts: {},
                requirements: {
                    food: 5500000,
                    iron: 6300000,
                    nuApe: 622200,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 424800,
                upgradeBoosts: {},
                requirements: {
                    food: 6000000,
                    iron: 6900000,
                    nuApe: 640900,
                },
                dependencies: [],
            },
        ],
    },
    modifiedVehicles: {
        image: '',
        names: {
            ru: 'Модифицированные Транспорные Средства',
            en: 'Modified Vehicles',
        },
        id: 'modifiedVehicles',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Титановый страж',
                        id: 'titaniumGuard',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    velocityCannon: {
        image: '',
        names: {
            ru: 'Пушка Высокой Скорости',
            en: 'Velocity Cannon',
        },
        id: 'velocityCannon',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Кинетический двигатель',
                        id: 'kineticEngine',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    showerOfBullets: {
        image: '',
        names: {
            ru: 'Дождь из пуль',
            en: 'Shower Of Bullets',
        },
        id: 'showerOfBullets',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Броня Жизни',
                        id: 'lifeArmor',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
    powerBlast: {
        image: '',
        names: {
            ru: 'Взрыв Силы',
            en: 'Power Blast',
        },
        id: 'powerBlast',
        levels: [
            {
                level: 0,
                startTime: 69960,
                upgradeBoosts: {},
                requirements: {
                    food: 950000,
                    iron: 1100000,
                    nuApe: 365500,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 76980,
                upgradeBoosts: {},
                requirements: {
                    food: 1000000,
                    iron: 1200000,
                    nuApe: 376400,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 2,
                    },
                ],
            },
            {
                level: 2,
                startTime: 84660,
                upgradeBoosts: {},
                requirements: {
                    food: 1100000,
                    iron: 1300000,
                    nuApe: 387700,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 3,
                    },
                ],
            },
            {
                level: 3,
                startTime: 90000,
                upgradeBoosts: {},
                requirements: {
                    food: 1200000,
                    iron: 1400000,
                    nuApe: 399300,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 4,
                    },
                ],
            },
            {
                level: 4,
                startTime: 100800,
                upgradeBoosts: {},
                requirements: {
                    food: 1400000,
                    iron: 1600000,
                    nuApe: 411300,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 5,
                    },
                ],
            },
            {
                level: 5,
                startTime: 111600,
                upgradeBoosts: {},
                requirements: {
                    food: 1500000,
                    iron: 1800000,
                    nuApe: 423700,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 6,
                    },
                ],
            },
            {
                level: 6,
                startTime: 122400,
                upgradeBoosts: {},
                requirements: {
                    food: 1700000,
                    iron: 1900000,
                    nuApe: 436400,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 7,
                    },
                ],
            },
            {
                level: 7,
                startTime: 133200,
                upgradeBoosts: {},
                requirements: {
                    food: 1800000,
                    iron: 2100000,
                    nuApe: 449500,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 8,
                    },
                ],
            },
            {
                level: 8,
                startTime: 147600,
                upgradeBoosts: {},
                requirements: {
                    food: 2000000,
                    iron: 2400000,
                    nuApe: 463000,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 9,
                    },
                ],
            },
            {
                level: 9,
                startTime: 162000,
                upgradeBoosts: {},
                requirements: {
                    food: 2300000,
                    iron: 2600000,
                    nuApe: 476800,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 10,
                    },
                ],
            },
            {
                level: 10,
                startTime: 180000,
                upgradeBoosts: {},
                requirements: {
                    food: 2500000,
                    iron: 2900000,
                    nuApe: 491200,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 11,
                    },
                ],
            },
            {
                level: 11,
                startTime: 198000,
                upgradeBoosts: {},
                requirements: {
                    food: 2800000,
                    iron: 3200000,
                    nuApe: 505900,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 12,
                    },
                ],
            },
            {
                level: 12,
                startTime: 219600,
                upgradeBoosts: {},
                requirements: {
                    food: 3000000,
                    iron: 3500000,
                    nuApe: 521100,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 13,
                    },
                ],
            },
            {
                level: 13,
                startTime: 241200,
                upgradeBoosts: {},
                requirements: {
                    food: 3400000,
                    iron: 3900000,
                    nuApe: 536700,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 14,
                    },
                ],
            },
            {
                level: 14,
                startTime: 262800,
                upgradeBoosts: {},
                requirements: {
                    food: 3700000,
                    iron: 4300000,
                    nuApe: 552800,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 15,
                    },
                ],
            },
            {
                level: 15,
                startTime: 291600,
                upgradeBoosts: {},
                requirements: {
                    food: 4100000,
                    iron: 4700000,
                    nuApe: 569400,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 16,
                    },
                ],
            },
            {
                level: 16,
                startTime: 320400,
                upgradeBoosts: {},
                requirements: {
                    food: 4500000,
                    iron: 5200000,
                    nuApe: 586500,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 17,
                    },
                ],
            },
            {
                level: 17,
                startTime: 352800,
                upgradeBoosts: {},
                requirements: {
                    food: 5000000,
                    iron: 5700000,
                    nuApe: 604100,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 18,
                    },
                ],
            },
            {
                level: 18,
                startTime: 388800,
                upgradeBoosts: {},
                requirements: {
                    food: 5500000,
                    iron: 6300000,
                    nuApe: 622200,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 19,
                    },
                ],
            },
            {
                level: 19,
                startTime: 424800,
                upgradeBoosts: {},
                requirements: {
                    food: 6000000,
                    iron: 6900000,
                    nuApe: 640900,
                },
                dependencies: [
                    {
                        name: 'Железная воля',
                        id: 'ironWill',
                        requiredLevel: 20,
                    },
                ],
            },
        ],
    },
    cannonFire: {
        image: '',
        names: {
            ru: 'Огонь орудий',
            en: 'Cannon Fire',
        },
        id: 'cannonFire',
        levels: [
            {
                level: 0,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [
                    {
                        name: 'Модифицированные Транспорные Средства',
                        id: 'modifiedVehicles',
                        requiredLevel: 1,
                    },
                ],
            },
            {
                level: 1,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 2,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 3,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 4,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 5,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 6,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 7,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 8,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 9,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 10,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 11,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 12,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 13,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 14,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 15,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 16,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 17,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 18,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 19,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
            {
                level: 20,
                startTime: 0,
                upgradeBoosts: {},
                requirements: {
                    food: 0,
                    iron: 0,
                    nuApe: 0,
                },
                dependencies: [],
            },
        ],
    },
}