import { useEffect } from 'react'
import styles from './Modal.module.scss'

export const Modal = ({ isOpen, children, title, onClose, className }) => {

  useEffect(() => {
    const body = document.querySelector('body')

    if (isOpen) {
      const scrollBarWidth = window.innerWidth - body.offsetWidth
      body.style.overflow = 'hidden'
      body.style.paddingRight = `${scrollBarWidth}px` // Компенсация сдвига страницы
    } else {
      body.style.overflow = ''
      body.style.paddingRight = ''
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    // Добавление обработчика событий при открытом модальном окне
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      body.style.overflow = ''
      body.style.paddingRight = ''
      window.removeEventListener('keydown', handleKeyDown) // Очистка обработчика
    }
  }, [isOpen, onClose])

  if (!isOpen) {
    return null
  }

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={`${styles.modalContent} ${className ? className : ''}`} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <p>{title}</p>
          <button className={styles.closeButton} onClick={onClose}><span className="visually-hidden">Закрыть</span></button>
        </div>

        <div className={styles.main}>
          {children}
        </div>
      </div>
    </div>
  )
}
