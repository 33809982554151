import styles from './ResearchT6Page.module.scss'
import Header from "../../components/Header/Header";
import ResearchT6 from "../../components/ResearchT6/ResearchT6";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function ResearchT6Page() {
    const {  t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('header.item2')}</title>
            </Helmet>

            <Header />
            <ResearchT6 />
        </>
    )
}

export default ResearchT6Page;
