import styles from './Buildings.module.scss';
import Header from '../../components/Header/Header';
import { buildingTypes } from '../../data/buildingTypes';
import { useState, useEffect } from 'react';
import iconFood from '../../assets/images/icons/icon-food.png';
import iconIron from '../../assets/images/icons/icon-iron.png';
import iconBattery from '../../assets/images/icons/icon-battery.png';
import iconBattleMap from '../../assets/images/icons/icon-battle-map.png';
import iconGenes from '../../assets/images/icons/icon-genes.png';
import iconNuApe from '../../assets/images/icons/icon-nu-ape.png';
import {calculateNewTime} from '../../utils/common';
import Total from "../../components/Total/Total";
import BuildingCard from "../../components/BuildingCard/BuildingCard";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import SpeedUpPercent from "../../components/SpeedUpPercent/SpeedUpPercent";
import Container from "../../components/Container/Container";

const initialSelectedBuildingLevel = {
    system: {
        version: 1,
    },
    cityCouncil: {
        startLevel: 30,
        endLevel: 35,
    },
    researchLab: {
        startLevel: 30,
        endLevel: 35,
    },
    wareHouse: {
        startLevel: 30,
        endLevel: 35,
    },
    pilotsCamp: {
        startLevel: 30,
        endLevel: 32,
    },
    hittersCamp: {
        startLevel: 30,
        endLevel: 32,
    },
    shootersCamp: {
        startLevel: 30,
        endLevel: 32,
    },
    wallBreakersCamp: {
        startLevel: 30,
        endLevel: 32,
    },
    commandCenter: {
        startLevel: 1,
        endLevel: 1,
    },
    apesTavern: {
        startLevel: 1,
        endLevel: 1,
    },
    intelStation: {
        startLevel: 1,
        endLevel: 1,
    },
    medicalStation: {
        startLevel: 1,
        endLevel: 1,
    },
    gangEmbassy: {
        startLevel: 1,
        endLevel: 1,
    },
    defenseWalls: {
        startLevel: 1,
        endLevel: 1,
    },
    sentryTowers: {
        startLevel: 1,
        endLevel: 1,
    },
    bananaTree: {
        startLevel: 1,
        endLevel: 1,
    },
}

export function IconRequirement({ iconKey, width = 30, height = 30 }) {
    let srcImg = null

    switch (iconKey) {
        case 'food':
            srcImg = iconFood
            break
        case 'iron': srcImg = iconIron
            break
        case 'batteries': srcImg = iconBattery
            break
        case 'gangBattleMap': srcImg = iconBattleMap
            break
        case 'genes': srcImg = iconGenes
            break
        case 'nuApe': srcImg = iconNuApe
            break
        default: return null
    }

    return <img src={srcImg} width={width} height={height} alt=""/>
}

function Buildings() {
    const [percent, setPercent] = useState(200);
    const [buildingSums, setBuildingSums] = useState({});
    const [selectedBuildingLevel, setSelectedBuildingLevel] = useState(initialSelectedBuildingLevel)
    const {  t } = useTranslation()

    useEffect(() => {
        const localSelectedBuildingLevel = JSON.parse(localStorage.getItem('selectedBuildingLevel'))

        if (localSelectedBuildingLevel) {
            setSelectedBuildingLevel(localSelectedBuildingLevel)
        }
    }, [])

    const [total, setTotal] = useState({
        startTime: 0,
        currentTime: 0,
        requirements: {},
    })

    const handleSumChange = (newSum, type, startLevel, endLevel) => {
        setBuildingSums(prevSum => {
            const updatedBuildingSums = {...prevSum};

            updatedBuildingSums[type] = newSum;

            handleTotalChange(updatedBuildingSums)

            return updatedBuildingSums;
        });

        if (startLevel !== undefined && endLevel !== undefined) {
            const newSelectedBuildingLevel = {...selectedBuildingLevel, [type]: { startLevel, endLevel }}
            setSelectedBuildingLevel(newSelectedBuildingLevel)
            localStorage.setItem('selectedBuildingLevel', JSON.stringify(newSelectedBuildingLevel))
        }
    }

    const handleTotalChange = (newSum) => {
        const updatedTotal = {
            startTime: 0,
            currentTime: 0,
            requirements: {},
        };

        // Пересчитываем общие значения на основе всех текущих сумм зданий
        Object.values(newSum).forEach(buildingSum => {
            updatedTotal.startTime += buildingSum.startTime;
            updatedTotal.currentTime += calculateNewTime(buildingSum.startTime, percent);

            Object.keys(buildingSum.requirements).forEach(key => {
                if (updatedTotal.requirements.hasOwnProperty(key)) {
                    updatedTotal.requirements[key] += buildingSum.requirements[key];
                } else {
                    updatedTotal.requirements[key] = buildingSum.requirements[key];
                }
            });
        });

        // Обновляем состояние с новыми итоговыми значениями
        setTotal(updatedTotal);
    }

    const handleReset = () => {
        setSelectedBuildingLevel(initialSelectedBuildingLevel)
        setPercent(200)

        localStorage.removeItem('selectedBuildingLevel')
        localStorage.removeItem('buildingSpeedBoost')
    }

    return (
        <>
            <Helmet>
                <title>{t('header.item1')}</title>
            </Helmet>

            <Header/>

            <Container>
                <div className={styles.totalContainer}>
                    <div className={styles.percentContainer}>
                        <SpeedUpPercent percent={percent} setPercent={setPercent} localStorageKey="buildingSpeedBoost" />
                    </div>

                    <Total total={total} percent={percent} onClickReset={handleReset}/>
                </div>
            </Container>

            <div className={styles.list}>
                {buildingTypes.map((building) => (
                    <BuildingCard
                        building={building}
                        speedUpPercent={percent}
                        selectedLevels={selectedBuildingLevel[building.type]}
                        onSumChange={handleSumChange}
                    />
                ))}
            </div>
        </>
    );
}

export default Buildings;
