export const cityCouncils = [
    {
        level: 1,
        startTime: 2,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 5,
            },
        },
        requirements: {
            food: 3000,
            iron: 3000,
            batteries: 0,
        },
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 65,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 11,
            },
        },
        requirements: {
            food: 5000,
            iron: 5000,
            batteries: 0,
        },
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 305,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 18,
            },
        },
        requirements: {
            food: 8000,
            iron: 8000,
            batteries: 0,
        },
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 1800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 38,
            },
        },
        requirements: {
            food: 10000,
            iron: 10000,
            batteries: 0,
        },
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 7200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 108,
            },
        },
        requirements: {
            food: 12000,
            iron: 12000,
            batteries: 6,
        },
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 10380,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 248,
            },
        },
        requirements: {
            food: 34000,
            iron: 34000,
            batteries: 7,
        },
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 13800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 1,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 648,
            },
        },
        requirements: {
            food: 82000,
            iron: 82000,
            batteries: 8,
        },
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 19980,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 1.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1608,
            },
        },
        requirements: {
            food: 151000,
            iron: 151000,
            batteries: 9,
        },
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 46080,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 2.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 3608,
            },
        },
        requirements: {
            food: 274000,
            iron: 274000,
            batteries: 10,
        },
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 87360,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 2.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 8008,
            },
        },
        requirements: {
            food: 378400,
            iron: 378400,
            batteries: 15,
        },
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 104400,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 3,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 15788,
            },
        },
        requirements: {
            food: 441000,
            iron: 441000,
            batteries: 20,
        },
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 133200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 3.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 25488,
            },
        },
        requirements: {
            food: 478300,
            iron: 478300,
            batteries: 25,
        },
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 162000,
        type: 'cityCouncil',
        buildingSpeed: 4,
        buildingPower: 37988,
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 4,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 37988,
            },
        },
        requirements: {
            food: 632800,
            iron: 632800,
            batteries: 30,
        },
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 183600,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 4.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 52988,
            },
        },
        requirements: {
            food: 826700,
            iron: 826700,
            batteries: 35,
        },
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 226800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 71488,
            },
        },
        requirements: {
            food: 1400000,
            iron: 1400000,
            batteries: 40,
        },
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 230400,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 6.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 94488,
            },
        },
        requirements: {
            food: 2000000,
            iron: 2000000,
            batteries: 45,
        },
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 190800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 7.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 126188,
            },
        },
        requirements: {
            food: 2600000,
            iron: 2600000,
            batteries: 50,
        },
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 262800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 8.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 165788,
            },
        },
        requirements: {
            food: 3100000,
            iron: 3100000,
            batteries: 700,
        },
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 277200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 9.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 215288,
            },
        },
        requirements: {
            food: 3500000,
            iron: 3500000,
            batteries: 900,
        },
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 298800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 10.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 275288,
            },
        },
        requirements: {
            food: 4200000,
            iron: 4200000,
            batteries: 1100,
        },
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 313200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 11.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 350288,
            },
        },
        requirements: {
            food: 6300000,
            iron: 6300000,
            batteries: 1300,
        },
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 360000,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 12.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 445088,
            },
        },
        requirements: {
            food: 8800000,
            iron: 8800000,
            batteries: 1500,
        },
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 424800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 13.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 566088,
            },
        },
        requirements: {
            food: 13600000,
            iron: 13600000,
            batteries: 1700,
        },
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 878400,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 14.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 720088,
            },
        },
        requirements: {
            food: 20000000,
            iron: 20000000,
            batteries: 2000,
        },
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 1465200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 15.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 911088,
            },
        },
        requirements: {
            food: 25400000,
            iron: 25400000,
            batteries: 2500,
        },
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 1965600,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 17.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1151088,
            },
        },
        requirements: {
            food: 32200000,
            iron: 32200000,
            batteries: 3000,
        },
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 2797200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 19.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1457688,
            },
        },
        requirements: {
            food: 48500000,
            iron: 48500000,
            batteries: 3500,
        },
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 3747600,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 21.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1862688,
            },
        },
        requirements: {
            food: 75000000,
            iron: 75000000,
            batteries: 4000,
        },
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 4798800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 23.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 2383688,
            },
        },
        requirements: {
            food: 100000000,
            iron: 100000000,
            batteries: 4500,
        },
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 97200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 26.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 3090272,
            },
        },
        requirements: {
            food: 2100000,
            iron: 2100000,
            batteries: 1000,
            nuApe: 44500,
        },
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 295200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 27.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 3124977,
            },
        },
        requirements: {
            food: 6300000,
            iron: 6300000,
            batteries: 1500,
            nuApe: 266000,
        },
        buildingRequirements: {},
    },
    {
        level: 32,
        startTime: 1670400,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 0,
            },
        },
        requirements: {
            food: 36200000,
            iron: 36200000,
            batteries: 2000,
            nuApe: 1500000,
        },
        buildingRequirements: {},
    },
    {
        level: 33,
        startTime: 2458800,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 0,
            },
        },
        requirements: {
            food: 53200000,
            iron: 53200000,
            batteries: 3000,
            nuApe: 2200000,
        },
        buildingRequirements: {},
    },
    {
        level: 34,
        startTime: 3445200,
        type: 'cityCouncil',
        upgradeBoosts: {
            buildingSpeed: {
                label: 'Скорость строительства',
                id: 'buildingSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 0,
            },
        },
        requirements: {
            food: 74500000,
            iron: 74500000,
            batteries: 5000,
            nuApe: 3100000,
        },
        buildingRequirements: {},
    },
]

export const researchLabs = [
    {
        level: 1,
        startTime: 2,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 10,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 3,
            },
        },
        requirements: {
            food: 3000,
            iron: 3000,
            batteries: 2,
        },
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 2,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 6,
            },
        },
        requirements: {
            food: 3600,
            iron: 3600,
            batteries: 4,
        },
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 2,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 9,
            },
        },
        requirements: {
            food: 4200,
            iron: 4200,
            batteries: 6,
        },
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 299,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 19,
            },
        },
        requirements: {
            food: 5000,
            iron: 5000,
            batteries: 10,
        },
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 598,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 54,
            },
        },
        requirements: {
            food: 6000,
            iron: 6000,
            batteries: 12,
        },
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1731,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 124,
            },
        },
        requirements: {
            food: 17000,
            iron: 17000,
            batteries: 14,
        },
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 4140,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 1.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 324,
            },
        },
        requirements: {
            food: 41000,
            iron: 41000,
            batteries: 16,
        },
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 9960,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 0,
            },
        },
        requirements: {
            food: 75500,
            iron: 75500,
            batteries: 18,
        },
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 23040,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 2.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1804,
            },
        },
        requirements: {
            food: 137000,
            iron: 137000,
            batteries: 20,
        },
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 43680,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 2.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 4004,
            },
        },
        requirements: {
            food: 189200,
            iron: 189200,
            batteries: 30,
        },
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 53280,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 3.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 7894,
            },
        },
        requirements: {
            food: 220500,
            iron: 220500,
            batteries: 40,
        },
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 67200,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 3.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 12744,
            },
        },
        requirements: {
            food: 239200,
            iron: 239200,
            batteries: 50,
        },
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 81000,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 4.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 18994,
            },
        },
        requirements: {
            food: 316400,
            iron: 316400,
            batteries: 60,
        },
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 90000,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 4.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 26494,
            },
        },
        requirements: {
            food: 413400,
            iron: 413400,
            batteries: 70,
        },
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 111600,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 5.5,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 35744,
            },
        },
        requirements: {
            food: 741500,
            iron: 741500,
            batteries: 80,
        },
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 115200,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 6.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 47244,
            },
        },
        requirements: {
            food: 1000000,
            iron: 1000000,
            batteries: 90,
        },
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 115200,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 7.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 63094,
            },
        },
        requirements: {
            food: 1300000,
            iron: 1300000,
            batteries: 100,
        },
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 129600,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 8.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 82894,
            },
        },
        requirements: {
            food: 1500000,
            iron: 1500000,
            batteries: 1400,
        },
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 136800,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 9.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 107644,
            },
        },
        requirements: {
            food: 1700000,
            iron: 1700000,
            batteries: 1800,
        },
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 147600,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 10,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 137644,
            },
        },
        requirements: {
            food: 2100000,
            iron: 2100000,
            batteries: 2200,
        },
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 154800,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 11.0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 175144,
            },
        },
        requirements: {
            food: 3100000,
            iron: 3100000,
            batteries: 2600,
        },
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 180000,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 12,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 222544,
            },
        },
        requirements: {
            food: 4400000,
            iron: 4400000,
            batteries: 3000,
        },
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 212400,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 13,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 283044,
            },
        },
        requirements: {
            food: 6800000,
            iron: 6800000,
            batteries: 3400,
        },
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 439200,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 14,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 360044,
            },
        },
        requirements: {
            food: 10000000,
            iron: 10000000,
            batteries: 4000,
        },
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 730800,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 15,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 455544,
            },
        },
        requirements: {
            food: 12700000,
            iron: 12700000,
            batteries: 5000,
        },
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 982800,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 17,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 575544,
            },
        },
        requirements: {
            food: 16100000,
            iron: 16100000,
            batteries: 6000,
        },
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1396800,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 19,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 728844,
            },
        },
        requirements: {
            food: 24200000,
            iron: 24200000,
            batteries: 7000,
        },
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1872000,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 21,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 931344,
            },
        },
        requirements: {
            food: 37500000,
            iron: 37500000,
            batteries: 8000,
        },
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 2397600,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 23,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1191844,
            },
        },
        requirements: {
            food: 50000000,
            iron: 50000000,
            batteries: 9000,
        },
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 65640,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 26,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1547054,
            },
        },
        requirements: {
            food: 1100000,
            iron: 1700000,
            batteries: 2000,
            nuApe: 29500,
        },
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 194400,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 27,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1547054,
            },
        },
        requirements: {
            food: 3400000,
            iron: 5100000,
            batteries: 3000,
            nuApe: 177000,
        },
        buildingRequirements: {},
    },
    {
        level: 32,
        startTime: 1112400,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 25,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 1539344,
            },
        },
        requirements: {
            food: 19300000,
            iron: 28900000,
            batteries: 4000,
            nuApe: 1000000,
        },
        buildingRequirements: {},
    },
    {
        level: 33,
        startTime: 1638000,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 0,
            },
        },
        requirements: {
            food: 28300000,
            iron: 42500000,
            batteries: 6000,
            nuApe: 1400000,
        },
        buildingRequirements: {},
    },
    {
        level: 34,
        startTime: 2296800,
        type: 'researchLab',
        upgradeBoosts: {
            researchSpeed: {
                label: 'Скорость исследований',
                id: 'researchSpeed',
                value: 0,
            },
            buildingPower: {
                label: 'Сила построек',
                id: 'buildingPower',
                value: 0,
            },
        },
        requirements: {
            food: 39700000,
            iron: 59600000,
            batteries: 10000,
            nuApe: 2000000,
        },
        buildingRequirements: {},
    },
]

export const wareHouse = [
    {
        level: 1,
        startTime: 2,
        type: 'wareHouse',
        requirements: {
            food: 2000,
            iron: 2000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'wareHouse',
        requirements: {
            food: 2800,
            iron: 2800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 65,
        type: 'wareHouse',
        requirements: {
            food: 2800,
            iron: 2800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 305,
        type: 'wareHouse',
        requirements: {
            food: 4200,
            iron: 4200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 603,
        type: 'wareHouse',
        requirements: {
            food: 4800,
            iron: 4800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1375,
        type: 'wareHouse',
        requirements: {
            food: 13600,
            iron: 13600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 3324,
        type: 'wareHouse',
        requirements: {
            food: 32800,
            iron: 32800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 7980,
        type: 'wareHouse',
        requirements: {
            food: 60400,
            iron: 60400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 18420,
        type: 'wareHouse',
        requirements: {
            food: 109600,
            iron: 109600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 34920,
        type: 'wareHouse',
        requirements: {
            food: 151400,
            iron: 151400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 42660,
        type: 'wareHouse',
        requirements: {
            food: 176400,
            iron: 176400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 53760,
        type: 'wareHouse',
        requirements: {
            food: 191300,
            iron: 191300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 64800,
        type: 'wareHouse',
        requirements: {
            food: 253100,
            iron: 253100,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 73500,
        type: 'wareHouse',
        requirements: {
            food: 330700,
            iron: 330700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 90000,
        type: 'wareHouse',
        requirements: {
            food: 593200,
            iron: 593200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 90000,
        type: 'wareHouse',
        requirements: {
            food: 822500,
            iron: 822500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 93600,
        type: 'wareHouse',
        requirements: {
            food: 1000000,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 104400,
        type: 'wareHouse',
        requirements: {
            food: 1200000,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 111600,
        type: 'wareHouse',
        requirements: {
            food: 1400000,
            iron: 1400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 118800,
        type: 'wareHouse',
        requirements: {
            food: 1600000,
            iron: 1600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 126000,
        type: 'wareHouse',
        requirements: {
            food: 2500000,
            iron: 2500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 144000,
        type: 'wareHouse',
        requirements: {
            food: 3500000,
            iron: 3500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 169200,
        type: 'wareHouse',
        requirements: {
            food: 5400000,
            iron: 5400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 349200,
        type: 'wareHouse',
        requirements: {
            food: 8000000,
            iron: 8000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 583200,
        type: 'wareHouse',
        requirements: {
            food: 10100000,
            iron: 10100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 784800,
        type: 'wareHouse',
        requirements: {
            food: 12900000,
            iron: 12900000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1119600,
        type: 'wareHouse',
        requirements: {
            food: 19400000,
            iron: 19400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1497600,
        type: 'wareHouse',
        requirements: {
            food: 30000000,
            iron: 30000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1918800,
        type: 'wareHouse',
        requirements: {
            food: 40000000,
            iron: 40000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 65640,
        type: 'wareHouse',
        requirements: {
            food: 1700000,
            iron: 1100000,
            nuApe: 29500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 194400,
        type: 'wareHouse',
        requirements: {
            food: 5100000,
            iron: 3400000,
            nuApe: 177000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 32,
        startTime: 1112400,
        type: 'wareHouse',
        requirements: {
            food: 28900000,
            iron: 19300000,
            nuApe: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 33,
        startTime: 1638000,
        type: 'wareHouse',
        requirements: {
            food: 42500000,
            iron: 28300000,
            nuApe: 1400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 34,
        startTime: 2296800,
        type: 'wareHouse',
        requirements: {
            food: 59600000,
            iron: 39700000,
            nuApe: 2000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const pilotsCamp = [
    {
        level: 1,
        startTime: 2,
        type: 'pilotsCamp',
        requirements: {
            food: 1800,
            iron: 2500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'pilotsCamp',
        requirements: {
            food: 2200,
            iron: 3000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 65,
        type: 'pilotsCamp',
        requirements: {
            food: 3600,
            iron: 4800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 300,
        type: 'pilotsCamp',
        requirements: {
            food: 3000,
            iron: 5400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 478,
        type: 'pilotsCamp',
        requirements: {
            food: 3600,
            iron: 6000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1375,
        type: 'pilotsCamp',
        requirements: {
            food: 10200,
            iron: 17000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 3324,
        type: 'pilotsCamp',
        requirements: {
            food: 24600,
            iron: 41000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 7980,
        type: 'pilotsCamp',
        requirements: {
            food: 45300,
            iron: 75500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 18420,
        type: 'pilotsCamp',
        requirements: {
            food: 82200,
            iron: 137000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 34920,
        type: 'pilotsCamp',
        requirements: {
            food: 113500,
            iron: 189200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 42660,
        type: 'pilotsCamp',
        requirements: {
            food: 132300,
            iron: 220500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 53760,
        type: 'pilotsCamp',
        requirements: {
            food: 143500,
            iron: 239200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 64800,
        type: 'pilotsCamp',
        requirements: {
            food: 189800,
            iron: 316400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 73500,
        type: 'pilotsCamp',
        requirements: {
            food: 248000,
            iron: 413400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 90000,
        type: 'pilotsCamp',
        requirements: {
            food: 444900,
            iron: 741500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 90000,
        type: 'pilotsCamp',
        requirements: {
            food: 616900,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 93600,
        type: 'pilotsCamp',
        requirements: {
            food: 793500,
            iron: 1300000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 104400,
        type: 'pilotsCamp',
        requirements: {
            food: 947200,
            iron: 1500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 111600,
        type: 'pilotsCamp',
        requirements: {
            food: 1000000,
            iron: 1700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 118800,
        type: 'pilotsCamp',
        requirements: {
            food: 1200000,
            iron: 2100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 126000,
        type: 'pilotsCamp',
        requirements: {
            food: 1900000,
            iron: 3100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 144000,
        type: 'pilotsCamp',
        requirements: {
            food: 2600000,
            iron: 4400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 169200,
        type: 'pilotsCamp',
        requirements: {
            food: 4000000,
            iron: 6800000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 349200,
        type: 'pilotsCamp',
        requirements: {
            food: 6000000,
            iron: 10000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 583200,
        type: 'pilotsCamp',
        requirements: {
            food: 7600000,
            iron: 12700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 784800,
        type: 'pilotsCamp',
        requirements: {
            food: 9600000,
            iron: 16100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1119600,
        type: 'pilotsCamp',
        requirements: {
            food: 14500000,
            iron: 24200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1497600,
        type: 'pilotsCamp',
        requirements: {
            food: 22500000,
            iron: 37500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1918800,
        type: 'pilotsCamp',
        requirements: {
            food: 30000000,
            iron: 50000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 122400,
        type: 'pilotsCamp',
        requirements: {
            food: 2900000,
            iron: 2300000,
            nuApe: 111000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 367200,
        type: 'pilotsCamp',
        requirements: {
            food: 8700000,
            iron: 7100000,
            nuApe: 333000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const hittersCamp = [
    {
        level: 1,
        startTime: 2,
        type: 'hittersCamp',
        requirements: {
            food: 3000,
            iron: 2500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'hittersCamp',
        requirements: {
            food: 3600,
            iron: 2800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 65,
        type: 'hittersCamp',
        requirements: {
            food: 4000,
            iron: 3200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 300,
        type: 'hittersCamp',
        requirements: {
            food: 4800,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 478,
        type: 'hittersCamp',
        requirements: {
            food: 5400,
            iron: 4200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1375,
        type: 'hittersCamp',
        requirements: {
            food: 15300,
            iron: 11900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 3324,
        type: 'hittersCamp',
        requirements: {
            food: 36900,
            iron: 28700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 7980,
        type: 'hittersCamp',
        requirements: {
            food: 68000,
            iron: 52900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 18420,
        type: 'hittersCamp',
        requirements: {
            food: 123300,
            iron: 95900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 34920,
        type: 'hittersCamp',
        requirements: {
            food: 170300,
            iron: 132400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 42660,
        type: 'hittersCamp',
        requirements: {
            food: 198500,
            iron: 154400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 53760,
        type: 'hittersCamp',
        requirements: {
            food: 215200,
            iron: 167400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 64800,
        type: 'hittersCamp',
        requirements: {
            food: 189800,
            iron: 316400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 73500,
        type: 'hittersCamp',
        requirements: {
            food: 372000,
            iron: 289300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 90000,
        type: 'hittersCamp',
        requirements: {
            food: 667300,
            iron: 519000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 90000,
        type: 'hittersCamp',
        requirements: {
            food: 925300,
            iron: 719700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 93600,
        type: 'hittersCamp',
        requirements: {
            food: 1100000,
            iron: 925800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 104400,
        type: 'hittersCamp',
        requirements: {
            food: 1400000,
            iron: 1100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 111600,
        type: 'hittersCamp',
        requirements: {
            food: 1500000,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 118800,
        type: 'hittersCamp',
        requirements: {
            food: 1800000,
            iron: 1400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 126000,
        type: 'hittersCamp',
        requirements: {
            food: 2800000,
            iron: 2200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 144000,
        type: 'hittersCamp',
        requirements: {
            food: 4000000,
            iron: 3100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 169200,
        type: 'hittersCamp',
        requirements: {
            food: 6100000,
            iron: 4700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 349200,
        type: 'hittersCamp',
        requirements: {
            food: 9000000,
            iron: 7000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 583200,
        type: 'hittersCamp',
        requirements: {
            food: 11400000,
            iron: 8800000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 784800,
        type: 'hittersCamp',
        requirements: {
            food: 14500000,
            iron: 11200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1119600,
        type: 'hittersCamp',
        requirements: {
            food: 21800000,
            iron: 16900000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1497600,
        type: 'hittersCamp',
        requirements: {
            food: 33700000,
            iron: 26200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1918800,
        type: 'hittersCamp',
        requirements: {
            food: 45000000,
            iron: 35000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 122400,
        type: 'hittersCamp',
        requirements: {
            food: 3100000,
            iron: 2100000,
            nuApe: 111000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 367200,
        type: 'hittersCamp',
        requirements: {
            food: 9500000,
            iron: 6300000,
            nuApe: 333000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const shootersCamp = [
    {
        level: 1,
        startTime: 2,
        type: 'shootersCamp',
        requirements: {
            food: 3000,
            iron: 2500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'shootersCamp',
        requirements: {
            food: 3600,
            iron: 2800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 65,
        type: 'shootersCamp',
        requirements: {
            food: 4000,
            iron: 3200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 300,
        type: 'shootersCamp',
        requirements: {
            food: 4800,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 478,
        type: 'shootersCamp',
        requirements: {
            food: 5400,
            iron: 4200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1375,
        type: 'shootersCamp',
        requirements: {
            food: 17000,
            iron: 10200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 3324,
        type: 'shootersCamp',
        requirements: {
            food: 41000,
            iron: 24600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 7980,
        type: 'shootersCamp',
        requirements: {
            food: 75500,
            iron: 45300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 18420,
        type: 'shootersCamp',
        requirements: {
            food: 137000,
            iron: 82200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 34920,
        type: 'shootersCamp',
        requirements: {
            food: 189200,
            iron: 113500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 42660,
        type: 'shootersCamp',
        requirements: {
            food: 220500,
            iron: 132300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 53760,
        type: 'shootersCamp',
        requirements: {
            food: 239200,
            iron: 143500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 64800,
        type: 'shootersCamp',
        requirements: {
            food: 316400,
            iron: 189800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 73500,
        type: 'shootersCamp',
        requirements: {
            food: 413400,
            iron: 248000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 90000,
        type: 'shootersCamp',
        requirements: {
            food: 741500,
            iron: 444900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 90000,
        type: 'shootersCamp',
        requirements: {
            food: 616900,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 93600,
        type: 'shootersCamp',
        requirements: {
            food: 1300000,
            iron: 793500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 104400,
        type: 'shootersCamp',
        requirements: {
            food: 1500000,
            iron: 947200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 111600,
        type: 'shootersCamp',
        requirements: {
            food: 1700000,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 118800,
        type: 'shootersCamp',
        requirements: {
            food: 2100000,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 126000,
        type: 'shootersCamp',
        requirements: {
            food: 3100000,
            iron: 1900000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 144000,
        type: 'shootersCamp',
        requirements: {
            food: 4400000,
            iron: 2600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 169200,
        type: 'shootersCamp',
        requirements: {
            food: 6800000,
            iron: 4000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 349200,
        type: 'shootersCamp',
        requirements: {
            food: 10000000,
            iron: 6000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 583200,
        type: 'shootersCamp',
        requirements: {
            food: 12700000,
            iron: 7600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 784800,
        type: 'shootersCamp',
        requirements: {
            food: 16100000,
            iron: 9600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1119600,
        type: 'shootersCamp',
        requirements: {
            food: 24200000,
            iron: 14500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1497600,
        type: 'shootersCamp',
        requirements: {
            food: 37500000,
            iron: 22500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1918800,
        type: 'shootersCamp',
        requirements: {
            food: 50000000,
            iron: 30000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 122400,
        type: 'shootersCamp',
        requirements: {
            food: 2100000,
            iron: 3100000,
            nuApe: 111000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 367200,
        type: 'shootersCamp',
        requirements: {
            food: 6300000,
            iron: 9500000,
            nuApe: 333000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const wallBreakersCamp = [
    {
        level: 1,
        startTime: 2,
        type: 'wallBreakersCamp',
        requirements: {
            food: 1800,
            iron: 2500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'wallBreakersCamp',
        requirements: {
            food: 2200,
            iron: 3000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 65,
        type: 'wallBreakersCamp',
        requirements: {
            food: 3600,
            iron: 4800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 300,
        type: 'wallBreakersCamp',
        requirements: {
            food: 3000,
            iron: 5400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 478,
        type: 'wallBreakersCamp',
        requirements: {
            food: 3600,
            iron: 6000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1375,
        type: 'wallBreakersCamp',
        requirements: {
            food: 11900,
            iron: 15300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 3324,
        type: 'wallBreakersCamp',
        requirements: {
            food: 28700,
            iron: 36900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 7980,
        type: 'wallBreakersCamp',
        requirements: {
            food: 52900,
            iron: 68000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 18420,
        type: 'wallBreakersCamp',
        requirements: {
            food: 95900,
            iron: 123300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 34920,
        type: 'wallBreakersCamp',
        requirements: {
            food: 132400,
            iron: 170300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 42660,
        type: 'wallBreakersCamp',
        requirements: {
            food: 154400,
            iron: 198500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 53760,
        type: 'wallBreakersCamp',
        requirements: {
            food: 167400,
            iron: 215200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 64800,
        type: 'wallBreakersCamp',
        requirements: {
            food: 221500,
            iron: 284800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 73500,
        type: 'wallBreakersCamp',
        requirements: {
            food: 289300,
            iron: 372000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 90000,
        type: 'wallBreakersCamp',
        requirements: {
            food: 519000,
            iron: 667300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 90000,
        type: 'wallBreakersCamp',
        requirements: {
            food: 719700,
            iron: 925300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 93600,
        type: 'wallBreakersCamp',
        requirements: {
            food: 925800,
            iron: 1100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 104400,
        type: 'wallBreakersCamp',
        requirements: {
            food: 1100000,
            iron: 1400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 111600,
        type: 'wallBreakersCamp',
        requirements: {
            food: 1200000,
            iron: 1500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 118800,
        type: 'wallBreakersCamp',
        requirements: {
            food: 1400000,
            iron: 1800000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 126000,
        type: 'wallBreakersCamp',
        requirements: {
            food: 2200000,
            iron: 2800000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 144000,
        type: 'wallBreakersCamp',
        requirements: {
            food: 3100000,
            iron: 4000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 169200,
        type: 'wallBreakersCamp',
        requirements: {
            food: 4700000,
            iron: 6100000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 349200,
        type: 'wallBreakersCamp',
        requirements: {
            food: 7000000,
            iron: 9000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 583200,
        type: 'wallBreakersCamp',
        requirements: {
            food: 8800000,
            iron: 11400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 784800,
        type: 'wallBreakersCamp',
        requirements: {
            food: 11200000,
            iron: 14500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1119600,
        type: 'wallBreakersCamp',
        requirements: {
            food: 16900000,
            iron: 21800000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1497600,
        type: 'wallBreakersCamp',
        requirements: {
            food: 26200000,
            iron: 33700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1918800,
        type: 'wallBreakersCamp',
        requirements: {
            food: 35000000,
            iron: 45000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 30,
        startTime: 122400,
        type: 'wallBreakersCamp',
        requirements: {
            food: 2300000,
            iron: 2900000,
            nuApe: 111000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 31,
        startTime: 367200,
        type: 'wallBreakersCamp',
        requirements: {
            food: 7100000,
            iron: 8700000,
            nuApe: 333000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const commandCenter = [
    {
        level: 1,
        startTime: 2,
        type: 'commandCenter',
        requirements: {
            food: 2000,
            iron: 1500,
            gangBattleMap: 2,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 4,
        type: 'commandCenter',
        requirements: {
            food: 2800,
            iron: 1800,
            gangBattleMap: 5,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 60,
        type: 'commandCenter',
        requirements: {
            food: 3200,
            iron: 2200,
            gangBattleMap: 10,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 180,
        type: 'commandCenter',
        requirements: {
            food: 3600,
            iron: 2600,
            gangBattleMap: 20,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 360,
        type: 'commandCenter',
        requirements: {
            food: 4200,
            iron: 3000,
            gangBattleMap: 30,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1031,
        type: 'commandCenter',
        requirements: {
            food: 11900,
            iron: 8500,
            gangBattleMap: 50,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 2493,
        type: 'commandCenter',
        requirements: {
            food: 28700,
            iron: 20500,
            gangBattleMap: 70,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 6000,
        type: 'commandCenter',
        requirements: {
            food: 52900,
            iron: 37800,
            gangBattleMap: 100,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 13800,
        type: 'commandCenter',
        requirements: {
            food: 95900,
            iron: 68500,
            gangBattleMap: 120,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 26160,
        type: 'commandCenter',
        requirements: {
            food: 132400,
            iron: 94600,
            gangBattleMap: 150,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 31980,
        type: 'commandCenter',
        requirements: {
            food: 154400,
            iron: 110300,
            gangBattleMap: 200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 40320,
        type: 'commandCenter',
        requirements: {
            food: 167400,
            iron: 119600,
            gangBattleMap: 250,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 48600,
        type: 'commandCenter',
        requirements: {
            food: 221500,
            iron: 158200,
            gangBattleMap: 300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 55140,
        type: 'commandCenter',
        requirements: {
            food: 289300,
            iron: 206700,
            gangBattleMap: 400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 68880,
        type: 'commandCenter',
        requirements: {
            food: 519000,
            iron: 370700,
            gangBattleMap: 500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 69480,
        type: 'commandCenter',
        requirements: {
            food: 719700,
            iron: 514100,
            gangBattleMap: 600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 71040,
        type: 'commandCenter',
        requirements: {
            food: 925800,
            iron: 661300,
            gangBattleMap: 700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 78960,
        type: 'commandCenter',
        requirements: {
            food: 1100000,
            iron: 789300,
            gangBattleMap: 800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 84000,
        type: 'commandCenter',
        requirements: {
            food: 1200000,
            iron: 875000,
            gangBattleMap: 1000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 90000,
        type: 'commandCenter',
        requirements: {
            food: 1400000,
            iron: 1000000,
            gangBattleMap: 1200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 93600,
        type: 'commandCenter',
        requirements: {
            food: 2200000,
            iron: 1500000,
            gangBattleMap: 1500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 108000,
        type: 'commandCenter',
        requirements: {
            food: 3100000,
            iron: 2200000,
            gangBattleMap: 2000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 126000,
        type: 'commandCenter',
        requirements: {
            food: 4700000,
            iron: 3400000,
            gangBattleMap: 2500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 262800,
        type: 'commandCenter',
        requirements: {
            food: 7000000,
            iron: 5000000,
            gangBattleMap: 3000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 439200,
        type: 'commandCenter',
        requirements: {
            food: 8800000,
            iron: 6300000,
            gangBattleMap: 3500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 590400,
        type: 'commandCenter',
        requirements: {
            food: 11200000,
            iron: 8000000,
            gangBattleMap: 4000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 838800,
        type: 'commandCenter',
        requirements: {
            food: 16900000,
            iron: 12100000,
            gangBattleMap: 45000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1125000,
        type: 'commandCenter',
        requirements: {
            food: 26200000,
            iron: 18700000,
            gangBattleMap: 5000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1440000,
        type: 'commandCenter',
        requirements: {
            food: 35000000,
            iron: 25000000,
            gangBattleMap: 7500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const apesTavern = [
    {
        level: 1,
        startTime: 2,
        type: 'apesTavern',
        requirements: {
            food: 2000,
            iron: 1200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 4,
        type: 'apesTavern',
        requirements: {
            food: 2800,
            iron: 1600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 62,
        type: 'apesTavern',
        requirements: {
            food: 2800,
            iron: 1800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 303,
        type: 'apesTavern',
        requirements: {
            food: 4200,
            iron: 2000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 359,
        type: 'apesTavern',
        requirements: {
            food: 4800,
            iron: 2400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 992,
        type: 'apesTavern',
        requirements: {
            food: 13600,
            iron: 6800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 2493,
        type: 'apesTavern',
        requirements: {
            food: 32800,
            iron: 16400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 6000,
        type: 'apesTavern',
        requirements: {
            food: 60400,
            iron: 30200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 13800,
        type: 'apesTavern',
        requirements: {
            food: 109600,
            iron: 54800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 26160,
        type: 'apesTavern',
        requirements: {
            food: 151400,
            iron: 75700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 31980,
        type: 'apesTavern',
        requirements: {
            food: 176400,
            iron: 88200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 40320,
        type: 'apesTavern',
        requirements: {
            food: 191300,
            iron: 95700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 48600,
        type: 'apesTavern',
        requirements: {
            food: 253100,
            iron: 126600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 55140,
        type: 'apesTavern',
        requirements: {
            food: 330700,
            iron: 165300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 68880,
        type: 'apesTavern',
        requirements: {
            food: 593200,
            iron: 296600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 69480,
        type: 'apesTavern',
        requirements: {
            food: 822500,
            iron: 411300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 71040,
        type: 'apesTavern',
        requirements: {
            food: 1000000,
            iron: 529000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 78960,
        type: 'apesTavern',
        requirements: {
            food: 1200000,
            iron: 631400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 84000,
        type: 'apesTavern',
        requirements: {
            food: 1400000,
            iron: 700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 90000,
        type: 'apesTavern',
        requirements: {
            food: 1600000,
            iron: 840000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 93600,
        type: 'apesTavern',
        requirements: {
            food: 2500000,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 108000,
        type: 'apesTavern',
        requirements: {
            food: 3500000,
            iron: 1700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 126000,
        type: 'apesTavern',
        requirements: {
            food: 5400000,
            iron: 2700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 262800,
        type: 'apesTavern',
        requirements: {
            food: 8000000,
            iron: 4000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 439200,
        type: 'apesTavern',
        requirements: {
            food: 10100000,
            iron: 5000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 590400,
        type: 'apesTavern',
        requirements: {
            food: 12900000,
            iron: 6400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 838800,
        type: 'apesTavern',
        requirements: {
            food: 19400000,
            iron: 9700000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1125000,
        type: 'apesTavern',
        requirements: {
            food: 30000000,
            iron: 15000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1440000,
        type: 'apesTavern',
        requirements: {
            food: 40000000,
            iron: 20000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const intelStation = [
    {
        level: 1,
        startTime: 2,
        type: 'intelStation',
        requirements: {
            food: 1800,
            iron: 1800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 6,
        type: 'intelStation',
        requirements: {
            food: 2200,
            iron: 2200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 62,
        type: 'intelStation',
        requirements: {
            food: 3600,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 180,
        type: 'intelStation',
        requirements: {
            food: 3000,
            iron: 3000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 348,
        type: 'intelStation',
        requirements: {
            food: 3600,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1031,
        type: 'intelStation',
        requirements: {
            food: 10200,
            iron: 10200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 2493,
        type: 'intelStation',
        requirements: {
            food: 24600,
            iron: 24600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 6000,
        type: 'intelStation',
        requirements: {
            food: 45300,
            iron: 45300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 13800,
        type: 'intelStation',
        requirements: {
            food: 82200,
            iron: 82200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 26160,
        type: 'intelStation',
        requirements: {
            food: 113500,
            iron: 113500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 31980,
        type: 'intelStation',
        requirements: {
            food: 132300,
            iron: 132300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 40320,
        type: 'intelStation',
        requirements: {
            food: 143500,
            iron: 143500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 48600,
        type: 'intelStation',
        requirements: {
            food: 189800,
            iron: 189800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 55140,
        type: 'intelStation',
        requirements: {
            food: 248000,
            iron: 248000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 68880,
        type: 'intelStation',
        requirements: {
            food: 444900,
            iron: 444900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 69480,
        type: 'intelStation',
        requirements: {
            food: 616900,
            iron: 616900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 71040,
        type: 'intelStation',
        requirements: {
            food: 793500,
            iron: 793500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 78960,
        type: 'intelStation',
        requirements: {
            food: 947200,
            iron: 947200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 84000,
        type: 'intelStation',
        requirements: {
            food: 1000000,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 90000,
        type: 'intelStation',
        requirements: {
            food: 1200000,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 93600,
        type: 'intelStation',
        requirements: {
            food: 1900000,
            iron: 1900000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 108000,
        type: 'intelStation',
        requirements: {
            food: 2600000,
            iron: 2600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 126000,
        type: 'intelStation',
        requirements: {
            food: 4000000,
            iron: 4000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 262800,
        type: 'intelStation',
        requirements: {
            food: 6000000,
            iron: 6000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 439200,
        type: 'intelStation',
        requirements: {
            food: 7600000,
            iron: 7600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 590400,
        type: 'intelStation',
        requirements: {
            food: 9600000,
            iron: 9600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 838800,
        type: 'intelStation',
        requirements: {
            food: 14500000,
            iron: 14500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1125000,
        type: 'intelStation',
        requirements: {
            food: 22500000,
            iron: 22500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1440000,
        type: 'intelStation',
        requirements: {
            food: 30000000,
            iron: 30000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const medicalStation = [
    {
        level: 1,
        startTime: 2,
        type: 'medicalStation',
        requirements: {
            food: 1800,
            iron: 1800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'medicalStation',
        requirements: {
            food: 2200,
            iron: 2200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 62,
        type: 'medicalStation',
        requirements: {
            food: 3600,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 180,
        type: 'medicalStation',
        requirements: {
            food: 3000,
            iron: 3000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 360,
        type: 'medicalStation',
        requirements: {
            food: 3600,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1031,
        type: 'medicalStation',
        requirements: {
            food: 10200,
            iron: 10200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 2493,
        type: 'medicalStation',
        requirements: {
            food: 24600,
            iron: 24600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 6000,
        type: 'medicalStation',
        requirements: {
            food: 45300,
            iron: 45300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 13800,
        type: 'medicalStation',
        requirements: {
            food: 82200,
            iron: 82200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 26160,
        type: 'medicalStation',
        requirements: {
            food: 113500,
            iron: 113500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 31980,
        type: 'medicalStation',
        requirements: {
            food: 132300,
            iron: 132300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 40320,
        type: 'medicalStation',
        requirements: {
            food: 143500,
            iron: 143500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 48600,
        type: 'medicalStation',
        requirements: {
            food: 189800,
            iron: 189800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 55140,
        type: 'medicalStation',
        requirements: {
            food: 248000,
            iron: 248000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 68880,
        type: 'medicalStation',
        requirements: {
            food: 444900,
            iron: 444900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 69480,
        type: 'medicalStation',
        requirements: {
            food: 616900,
            iron: 616900,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 71040,
        type: 'medicalStation',
        requirements: {
            food: 793500,
            iron: 793500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 78960,
        type: 'medicalStation',
        requirements: {
            food: 947200,
            iron: 947200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 84000,
        type: 'medicalStation',
        requirements: {
            food: 1000000,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 90000,
        type: 'medicalStation',
        requirements: {
            food: 1200000,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 93600,
        type: 'medicalStation',
        requirements: {
            food: 1900000,
            iron: 1900000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 108000,
        type: 'medicalStation',
        requirements: {
            food: 2600000,
            iron: 2600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 126000,
        type: 'medicalStation',
        requirements: {
            food: 4000000,
            iron: 4000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 262800,
        type: 'medicalStation',
        requirements: {
            food: 6000000,
            iron: 6000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 439200,
        type: 'medicalStation',
        requirements: {
            food: 7600000,
            iron: 7600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 590400,
        type: 'medicalStation',
        requirements: {
            food: 9600000,
            iron: 9600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 838800,
        type: 'medicalStation',
        requirements: {
            food: 14500000,
            iron: 14500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1125000,
        type: 'medicalStation',
        requirements: {
            food: 22500000,
            iron: 22500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1440000,
        type: 'medicalStation',
        requirements: {
            food: 30000000,
            iron: 30000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const gangEmbassy = [
    {
        level: 1,
        startTime: 2,
        type: 'gangEmbassy',
        requirements: {
            food: 1800,
            iron: 2000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 4,
        type: 'gangEmbassy',
        requirements: {
            food: 2200,
            iron: 2800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 63,
        type: 'gangEmbassy',
        requirements: {
            food: 3600,
            iron: 3600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 305,
        type: 'gangEmbassy',
        requirements: {
            food: 3000,
            iron: 4200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 420,
        type: 'gangEmbassy',
        requirements: {
            food: 3600,
            iron: 4800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1203,
        type: 'gangEmbassy',
        requirements: {
            food: 10200,
            iron: 13600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 2909,
        type: 'gangEmbassy',
        requirements: {
            food: 24600,
            iron: 32800,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 6960,
        type: 'gangEmbassy',
        requirements: {
            food: 45300,
            iron: 60400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 16080,
        type: 'gangEmbassy',
        requirements: {
            food: 82200,
            iron: 109600,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 30540,
        type: 'gangEmbassy',
        requirements: {
            food: 113500,
            iron: 151400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 37320,
        type: 'gangEmbassy',
        requirements: {
            food: 132300,
            iron: 176400,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 47040,
        type: 'gangEmbassy',
        requirements: {
            food: 143500,
            iron: 191300,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 56700,
        type: 'gangEmbassy',
        requirements: {
            food: 189800,
            iron: 253100,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 64320,
        type: 'gangEmbassy',
        requirements: {
            food: 248000,
            iron: 330700,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 80400,
        type: 'gangEmbassy',
        requirements: {
            food: 444900,
            iron: 593200,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 81060,
        type: 'gangEmbassy',
        requirements: {
            food: 616900,
            iron: 822500,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 82920,
        type: 'gangEmbassy',
        requirements: {
            food: 793500,
            iron: 1000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 90000,
        type: 'gangEmbassy',
        requirements: {
            food: 947200,
            iron: 1200000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 97200,
        type: 'gangEmbassy',
        requirements: {
            food: 1000000,
            iron: 1400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 104400,
        type: 'gangEmbassy',
        requirements: {
            food: 1200000,
            iron: 1600000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 108000,
        type: 'gangEmbassy',
        requirements: {
            food: 1900000,
            iron: 2500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 126000,
        type: 'gangEmbassy',
        requirements: {
            food: 2600000,
            iron: 3500000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 147600,
        type: 'gangEmbassy',
        requirements: {
            food: 4000000,
            iron: 5400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 306000,
        type: 'gangEmbassy',
        requirements: {
            food: 6000000,
            iron: 8000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 511200,
        type: 'gangEmbassy',
        requirements: {
            food: 7600000,
            iron: 1010000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 687600,
        type: 'gangEmbassy',
        requirements: {
            food: 9600000,
            iron: 12900000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 979200,
        type: 'gangEmbassy',
        requirements: {
            food: 14500000,
            iron: 19400000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1310400,
        type: 'gangEmbassy',
        requirements: {
            food: 22500000,
            iron: 30000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 1677600,
        type: 'gangEmbassy',
        requirements: {
            food: 30000000,
            iron: 40000000,
        },
        upgradeBoosts: {},
        buildingRequirements: {},
    },
]

export const defenseWalls = [
    {
        level: 1,
        startTime: 3,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 1000,
            iron: 1000,
        },
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 5,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 2800,
            iron: 6000,
        },
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 120,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 3200,
            iron: 6400,
        },
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 300,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 3600,
            iron: 7200,
        },
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 598,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 4200,
            iron: 7800,
        },
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 1805,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 11900,
            iron: 22100,
        },
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 4140,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 28700,
            iron: 53300,
        },
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 9960,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 52900,
            iron: 98200,
        },
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 23040,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 95900,
            iron: 178100,
        },
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 43680,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 132400,
            iron: 246000,
        },
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 53280,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 154400,
            iron: 286700,
        },
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 67200,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 167400,
            iron: 310900,
        },
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 81000,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 221500,
            iron: 411300,
        },
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 90000,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 289300,
            iron: 537400,
        },
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 111600,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 51900,
            iron: 963900,
        },
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 115200,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 719700,
            iron: 1300000,
        },
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 115200,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 925800,
            iron: 1700000,
        },
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 129600,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 1100000,
            iron: 2000000,
        },
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 136800,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 1200000,
            iron: 2200000,
        },
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 147600,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 1400000,
            iron: 2700000,
        },
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 154800,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 2200000,
            iron: 4100000,
        },
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 180000,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 3100000,
            iron: 5700000,
        },
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 212400,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 4700000,
            iron: 8800000,
        },
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 439200,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 7000000,
            iron: 13000000,
        },
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 730800,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 8800000,
            iron: 16500000,
        },
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 982800,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 11200000,
            iron: 20900000,
        },
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 1396800,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 16900000,
            iron: 31500000,
        },
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 1872000,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 26200000,
            iron: 48700000,
        },
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 2397600,
        type: 'defenseWalls',
        upgradeBoosts: {},
        requirements: {
            food: 35000000,
            iron: 65000000,
        },
        buildingRequirements: {},
    },
]

export const sentryTowers = [
    {
        level: 1,
        startTime: 1,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 0,
            iron: 0,
            batteries: 7,
        },
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 4,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 600,
            iron: 600,
            batteries: 14,
        },
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 36,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 800,
            iron: 800,
            batteries: 21,
        },
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 120,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 1200,
            iron: 1200,
            batteries: 35,
        },
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 300,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 2400,
            iron: 2400,
            batteries: 42,
        },
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 688,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 6800,
            iron: 6800,
            batteries: 49,
        },
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 1662,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 16400,
            iron: 16400,
            batteries: 56,
        },
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 3960,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 30200,
            iron: 30200,
            batteries: 63,
        },
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 9180,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 54800,
            iron: 54800,
            batteries: 70,
        },
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 17460,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 75700,
            iron: 75700,
            batteries: 105,
        },
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 18300,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 88200,
            iron: 88200,
            batteries: 140,
        },
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 26880,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 95700,
            iron: 95700,
            batteries: 175,
        },
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 32400,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 126600,
            iron: 126600,
            batteries: 210,
        },
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 36720,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 165300,
            iron: 165300,
            batteries: 245,
        },
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 45900,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 296600,
            iron: 296600,
            batteries: 280,
        },
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 46320,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 411300,
            iron: 411300,
            batteries: 315,
        },
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 47340,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 529000,
            iron: 529000,
            batteries: 350,
        },
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 52620,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 631400,
            iron: 631400,
            batteries: 490,
        },
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 55980,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 700000,
            iron: 700000,
            batteries: 630,
        },
        buildingRequirements: {},
    },
    {
        level: 20,
        startTime: 60000,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 840000,
            iron: 840000,
            batteries: 770,
        },
        buildingRequirements: {},
    },
    {
        level: 21,
        startTime: 63000,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 1200000,
            iron: 1200000,
            batteries: 910,
        },
        buildingRequirements: {},
    },
    {
        level: 22,
        startTime: 72300,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 1700000,
            iron: 1700000,
            batteries: 1000,
        },
        buildingRequirements: {},
    },
    {
        level: 23,
        startTime: 85500,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 2700000,
            iron: 2700000,
            batteries: 1100,
        },
        buildingRequirements: {},
    },
    {
        level: 24,
        startTime: 363600,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 4000000,
            iron: 4000000,
            batteries: 1400,
        },
        buildingRequirements: {},
    },
    {
        level: 25,
        startTime: 291600,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 5000000,
            iron: 5000000,
            batteries: 1700,
        },
        buildingRequirements: {},
    },
    {
        level: 26,
        startTime: 392400,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 6400000,
            iron: 6400000,
            batteries: 2100,
        },
        buildingRequirements: {},
    },
    {
        level: 27,
        startTime: 558000,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 9700000,
            iron: 9700000,
            batteries: 2400,
        },
        buildingRequirements: {},
    },
    {
        level: 28,
        startTime: 748800,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 15000000,
            iron: 15000000,
            batteries: 2800,
        },
        buildingRequirements: {},
    },
    {
        level: 29,
        startTime: 957600,
        type: 'sentryTowers',
        upgradeBoosts: {},
        requirements: {
            food: 20000000,
            iron: 20000000,
            batteries: 3100,
        },
        buildingRequirements: {},
    },
]

export const bananaTree = [
    {
        level: 1,
        startTime: 63,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 300,
        },
        buildingRequirements: {},
    },
    {
        level: 2,
        startTime: 630,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 500,
        },
        buildingRequirements: {},
    },
    {
        level: 3,
        startTime: 1620,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 1000,
        },
        buildingRequirements: {},
    },
    {
        level: 4,
        startTime: 3240,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 1500,
        },
        buildingRequirements: {},
    },
    {
        level: 5,
        startTime: 5400,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 3000,
        },
        buildingRequirements: {},
    },
    {
        level: 6,
        startTime: 9720,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 5000,
        },
        buildingRequirements: {},
    },
    {
        level: 7,
        startTime: 16200,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 8000,
        },
        buildingRequirements: {},
    },
    {
        level: 8,
        startTime: 21600,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 12000,
        },
        buildingRequirements: {},
    },
    {
        level: 9,
        startTime: 32400,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 15000,
        },
        buildingRequirements: {},
    },
    {
        level: 10,
        startTime: 41460,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 20000,
        },
        buildingRequirements: {},
    },
    {
        level: 11,
        startTime: 57000,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 30000,
        },
        buildingRequirements: {},
    },
    {
        level: 12,
        startTime: 72540,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 50000,
        },
        buildingRequirements: {},
    },
    {
        level: 13,
        startTime: 90000,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 70000,
        },
        buildingRequirements: {},
    },
    {
        level: 14,
        startTime: 108000,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 110000,
        },
        buildingRequirements: {},
    },
    {
        level: 15,
        startTime: 140400,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 120000,
        },
        buildingRequirements: {},
    },
    {
        level: 16,
        startTime: 180000,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 130000,
        },
        buildingRequirements: {},
    },
    {
        level: 17,
        startTime: 244800,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 140000,
        },
        buildingRequirements: {},
    },
    {
        level: 18,
        startTime: 309600,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 160000,
        },
        buildingRequirements: {},
    },
    {
        level: 19,
        startTime: 388800,
        type: 'bananaTree',
        upgradeBoosts: {},
        requirements: {
            genes: 180000,
        },
        buildingRequirements: {},
    },
]
