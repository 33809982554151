import {useEffect, useState} from "react";
import {abbreviateNumber, calculateNewTime, secondsToDhms, sliceTime} from "../../utils/common";
import styles from "./ResearchCard.module.scss";
import {IconRequirement} from "../../pages/Buildings/Buildings";
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info.svg';
import {useTranslation} from "react-i18next";

function ResearchCard({ researchItem, selectedLevels, speedUpPercent, onSumChange, isUsedRelic, onOpenInfoModal, setResearchItem }) {
    const [level, setLevel] = useState(0)
    const [nextLevel, setNextLevel] = useState(1)
    const [requirements, setRequirements] = useState([])
    const [maxLevel, setMaxLevel] = useState(0)
    const [sum, setSum] = useState({
        startTime: 0,
        currentTime: 0,
        requirements: {},
    })

    const { i18n, t } = useTranslation()
    const lang = i18n?.resolvedLanguage

    // useEffect(() => {
    //     console.log(isUsedRelic)
    //
    //     if(isUsedRelic){
    //         filterNextLevel(researchItem.levels, selectedLevels)
    //     }
    // }, [isUsedRelic])

    useEffect(() => {
        const values = researchItem.levels

        checkRequirements(values);

        if (selectedLevels) {
            setLevel(selectedLevels.startLevel)
            setNextLevel(selectedLevels.endLevel)
            setMaxLevel(values.length)
        }

    }, [researchItem, selectedLevels])

    useEffect(() => {
        const values = researchItem.levels

        if (values && selectedLevels) {
            filterNextLevel(values, selectedLevels)
        }
    }, [speedUpPercent, selectedLevels, isUsedRelic])

    const checkRequirements = (items) => {
        const values = []

        items.forEach((item) => {
            Object.keys(item.requirements).forEach((el) => {
                if (!values.includes(el)) {
                    values.push(el)
                }
            })
        })

        setRequirements(values)
    }

    const handleLevel = (evt) => {
        const value = +evt.target.value;

        if (value <= nextLevel) {
            countSum(researchItem.levels.filter((item) => item.level >= value && item.level < nextLevel), value, selectedLevels.endLevel);
        }
    };

    const handleNextLevel = (evt) => {
        const value = +evt.target.value;

        if (value >= level) {
            countSum(researchItem.levels.filter((item) => item.level >= level && item.level < value), selectedLevels.startLevel, value);
        }
    };

    const filterNextLevel = (values, levels) => {
        countSum(values.filter((item) => item.level >= levels.startLevel && item.level < levels.endLevel));
    }

    const countSum = (values, startLevel, endLevel) => {
        const result = {
            startTime: 0,
            currentTime: 0,
            requirements: {},
        }

        values.forEach(item => {
            result.startTime += item.startTime
            result.currentTime += calculateNewTime(item.startTime, speedUpPercent, isUsedRelic)

            for (const key in item.requirements) {
                if (result.requirements.hasOwnProperty(key)) {
                    result.requirements[key] += item.requirements[key]
                } else {
                    result.requirements[key] = item.requirements[key]
                }
            }
        })

        setSum(result)
        if (startLevel !== undefined && endLevel !== undefined) {
            onSumChange(result, researchItem.id, startLevel, endLevel)
        } else {
            onSumChange(result, researchItem.id)
        }
    }

    const handleClickResearchInfo = () => {
        setResearchItem({
            item: researchItem,
            requirements,
        })
        onOpenInfoModal(true)
    }

    return (
        <div className={styles.item}>
            <div className={styles.itemContent}>
                {researchItem.image !== '' ? (
                    <img className={styles.image} src={researchItem.image} width={60} height={59} alt="" />
                ) : (
                    <div className={styles.imgWrap} />
                )}

                <div>
                    <p className={styles.name}>{researchItem.hasOwnProperty('names') ? researchItem.names[lang] : researchItem.name}</p>

                    <p className={styles.requirementsLabel}>
                        <span>{t('consumptionFor')}</span>
                        <select className={styles.select} onChange={handleLevel}>
                            {researchItem?.levels.map((el, index) => (
                                <option
                                    value={index}
                                    selected={selectedLevels?.startLevel === index}
                                    key={el.level}
                                    disabled={index > selectedLevels?.endLevel}
                                >
                                    {index}
                                </option>
                            ))}
                            <option
                                value={researchItem?.levels?.length}
                                selected={selectedLevels?.startLevel === researchItem?.levels?.length}
                                disabled={researchItem?.levels?.length > selectedLevels?.endLevel}
                            >
                                {researchItem?.levels?.length}
                            </option>
                        </select>
                        <span>></span>

                        <select className={styles.select} onChange={handleNextLevel}>
                            {researchItem?.levels.map((el, index) => (
                                <option
                                    value={index}
                                    selected={selectedLevels?.endLevel === index}
                                    disabled={index < selectedLevels?.startLevel}
                                    key={el.level}
                                >
                                    {index}
                                </option>
                            ))}
                            <option
                                value={researchItem?.levels?.length}
                                selected={selectedLevels?.endLevel === researchItem?.levels?.length}
                                disabled={researchItem?.levels?.length < selectedLevels?.startLevel}
                            >
                                {researchItem?.levels?.length}
                            </option>
                        </select>
                    </p>
                </div>
            </div>

            <div className={styles.bottom}>
                {Object.keys(sum.requirements).length > 0 && (
                    <>
                        <div className={styles.requirements}>
                            <div>
                                <p className={styles.timeWrap}>
                                    <span>{t('time')}:</span>
                                    <span
                                        className={styles.timeBuilding}>{sliceTime(secondsToDhms(sum.currentTime, lang,))}</span>
                                </p>
                            </div>

                            {Object.entries(sum.requirements).map(([key, value]) => (
                                <div className={styles.requirement} key={key}>
                                    <IconRequirement iconKey={key} width={18} height={18}/>
                                    <span>{abbreviateNumber(value)}</span>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <button className={styles.buttonInfo} onClick={handleClickResearchInfo}>
                <IconInfo className={styles.iconInfo} width={20} height={20}/>
            </button>
        </div>
    )
}

export default ResearchCard
