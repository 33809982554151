import styles from './ResearchT6.module.scss'
import ResearchCard from "../ResearchCard/ResearchCard";
import {weaponsTechnology} from "../../data/weaponsTechnology";
import {useEffect, useRef, useState} from "react";
import Total from "../Total/Total";
import {calculateNewTime, secondsToDhms} from "../../utils/common";
import {useTranslation} from "react-i18next";
import Container from "../Container/Container";
import SpeedUpPercent from "../SpeedUpPercent/SpeedUpPercent";
import {Modal} from "../Modal/Modal";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import BuildingInfoModal from "../BuildingInfoModal/BuildingInfoModal";

const initialSelectedResearchLevel = {
    system: {
        version: 1,
    },
    speedBomb: {
        startLevel: 0,
        endLevel: 5,
    },
    precisionSniper: {
        startLevel: 0,
        endLevel: 5,
    },
    dynamoHammer: {
        startLevel: 0,
        endLevel: 5,
    },
    velocityPenetrator: {
        startLevel: 0,
        endLevel: 5,
    },
    stardustBombs: {
        startLevel: 0,
        endLevel: 7,
    },
    momentumShield: {
        startLevel: 0,
        endLevel: 5,
    },
    securityBarrier: {
        startLevel: 0,
        endLevel: 5,
    },
    apeOfSteel: {
        startLevel: 0,
        endLevel: 5,
    },
    bulletproofChest: {
        startLevel: 0,
        endLevel: 5,
    },
    unitedWeStand: {
        startLevel: 0,
        endLevel: 7,
    },
    interstellarTravel: {
        startLevel: 0,
        endLevel: 7,
    },
    spaceWarrior: {
        startLevel: 0,
        endLevel: 7,
    },
    timeToSoar: {
        startLevel: 0,
        endLevel: 4,
    },
    hurricaneBullets: {
        startLevel: 0,
        endLevel: 4,
    },
    forceOfSpeed: {
        startLevel: 0,
        endLevel: 10,
    },
    chariotAssault: {
        startLevel: 0,
        endLevel: 4,
    },
    blastCores: {
        startLevel: 0,
        endLevel: 4,
    },
    pulseSniper: {
        startLevel: 0,
        endLevel: 4,
    },
    hiEnergyHammer: {
        startLevel: 0,
        endLevel: 10,
    },
    heavyExplosives: {
        startLevel: 0,
        endLevel: 4,
    },
    spaceWalk: {
        startLevel: 0,
        endLevel: 6,
    },
    earthshakers: {
        startLevel: 0,
        endLevel: 1,
    },
}

function EmptyCard({ label }) {
    const { i18n } = useTranslation()
    const lang = i18n?.resolvedLanguage

    return (
        <div className={styles.emptyCard}>
            <div className={styles.imgWrap}/>

            <div>
                <p className={styles.name}>{typeof label === 'object' && label[lang]}</p>
            </div>
        </div>
    )
}

function ResearchT6() {
    const trackWrapRef = useRef(null)
    const [speedUpPercent, setSpeedUpPercent] = useState(220)
    const [total, setTotal] = useState({
        startTime: 0,
        currentTime: 0,
        requirements: {},
    })
    const [buildingSums, setBuildingSums] = useState({})
    const [isOpenSpeedBoostInfoModal, setIsOpenSpeedBoostInfoModal] = useState(false)
    const [selectedResearchLevel, setSelectedResearchLevel] = useState(initialSelectedResearchLevel)
    const [isOpenModalInfo, setIsOpenModalInfo] = useState(false)
    const [researchItem, setResearchItem] = useState({})
    const [isUsedRelic, setIsUsedRelic] = useState(false)
    const {  i18n, t } = useTranslation()
    const lang = i18n?.resolvedLanguage

    useEffect(() => {
        const element = trackWrapRef.current;
        const localSelectedResearchLevel = JSON.parse(localStorage.getItem('selectedResearchLevel'))
        const localIsUsedRelic = JSON.parse(localStorage.getItem('isUsedRelic'))

        if (localSelectedResearchLevel) {
            setSelectedResearchLevel(localSelectedResearchLevel)
        }

        if (localIsUsedRelic) {
            setIsUsedRelic(true)
        }

        // const handleWheel = (event) => {
        //     event.preventDefault();
        //     element.scrollBy({
        //         left: event.deltaY < 0 ? -100 : 100,
        //     })
        // }
        //
        // element.addEventListener('wheel', handleWheel)
        //
        // return () => element.removeEventListener('wheel', handleWheel)
    }, [])

    const handleSumChange = (newSum, type, startLevel, endLevel) => {
        setBuildingSums(prevSum => {
            const updatedBuildingSums = {...prevSum};

            updatedBuildingSums[type] = newSum;

            handleTotalChange(updatedBuildingSums)

            return updatedBuildingSums;
        });

        if (startLevel !== undefined && endLevel !== undefined) {
            const newSelectedResearchLevel = {...selectedResearchLevel, [type]: { startLevel, endLevel }}
            setSelectedResearchLevel(newSelectedResearchLevel)
            localStorage.setItem('selectedResearchLevel', JSON.stringify(newSelectedResearchLevel))
        }
    }

    const handleTotalChange = (newSum) => {
        const updatedTotal = {
            startTime: 0,
            currentTime: 0,
            requirements: {},
        };

        // Пересчитываем общие значения на основе всех текущих сумм зданий
        Object.values(newSum).forEach(buildingSum => {
            updatedTotal.startTime += buildingSum.startTime;
            updatedTotal.currentTime += calculateNewTime(buildingSum.startTime, speedUpPercent, isUsedRelic);

            Object.keys(buildingSum.requirements).forEach(key => {
                if (updatedTotal.requirements.hasOwnProperty(key)) {
                    updatedTotal.requirements[key] += buildingSum.requirements[key];
                } else {
                    updatedTotal.requirements[key] = buildingSum.requirements[key];
                }
            });
        });

        // Обновляем состояние с новыми итоговыми значениями
        setTotal(updatedTotal);
    }

    const handleReset = () => {
        setSelectedResearchLevel(initialSelectedResearchLevel)
        setSpeedUpPercent(220)
        setIsUsedRelic(false)

        localStorage.removeItem('selectedResearchLevel')
        localStorage.removeItem('researchSpeedBoost')
        localStorage.removeItem('isUsedRelic')
    }

    const handleChangeCheckbox = (evt) => {
        const isChecked = evt.target.checked

        setIsUsedRelic(isChecked)

        if (isChecked) {
            localStorage.setItem('isUsedRelic', JSON.stringify(isChecked))
        } else {
            localStorage.removeItem('isUsedRelic')
        }
    }

    const handleCloseModalInfo = () => {
        setIsOpenModalInfo(false)
        setResearchItem({})
    }

    return (
        <>
            <div className={styles.totalWrap}>
                <Container>
                    <div className={styles.totalContainer}>
                        <div className={styles.percentContainer}>
                            <SpeedUpPercent percent={speedUpPercent} setPercent={setSpeedUpPercent}
                                            localStorageKey="researchSpeedBoost"/>

                            <div className={styles.checkboxContainer}>
                                <label className={styles.relicTotemLabel}>
                                    <input type="checkbox" onChange={handleChangeCheckbox} checked={isUsedRelic}/>
                                    {t('relicCheckboxLabel')}
                                </label>
                            </div>

                            <button className={styles.speedBoostInfoButton}
                                    onClick={() => setIsOpenSpeedBoostInfoModal(true)}>?
                            </button>
                        </div>

                        <Total total={total} percent={speedUpPercent} onClickReset={handleReset}/>

                        <div className={styles.dangerWrap}>
                            <p className={styles.danger}>- {t('t6DangerPart1')}</p>
                            <p className={styles.danger}>- {t('t6DangerPart2')}</p>
                            <p className={styles.danger}>- {t('t6DangerPart3')}</p>
                        </div>
                    </div>
                </Container>
            </div>

            <Swiper
                slidesPerView="auto"
                freeMode={true}
                modules={[FreeMode, Mousewheel]}
                grabCursor={true}
                mousewheel={true}
                className={styles.slider}
            >
                <SwiperSlide className={styles.slide}>
                    <div className={styles.trackWrap} ref={trackWrapRef}>
                        <div className={styles.track}>
                            <div className={styles.column}>
                                <ResearchCard
                                    researchItem={weaponsTechnology.speedBomb}
                                    selectedLevels={selectedResearchLevel.speedBomb}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.precisionSniper}
                                    selectedLevels={selectedResearchLevel.precisionSniper}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.dynamoHammer}
                                    selectedLevels={selectedResearchLevel.dynamoHammer}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.velocityPenetrator}
                                    selectedLevels={selectedResearchLevel.velocityPenetrator}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                            </div>

                            <div className={`${styles.column} ${styles.column2}`}>
                                <div className={`${styles.row}`}>
                                    <EmptyCard label={weaponsTechnology.capsuleBooster.names}/>
                                    <EmptyCard label={weaponsTechnology.enhancedRecipes.names}/>
                                </div>
                                <div className={`${styles.row} ${styles.row2}`}>
                                    <ResearchCard
                                        researchItem={weaponsTechnology.stardustBombs}
                                        selectedLevels={selectedResearchLevel.stardustBombs}
                                        isUsedRelic={isUsedRelic}
                                        speedUpPercent={speedUpPercent}
                                        onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                    />
                                </div>
                                <div className={`${styles.row}`}>
                                    <EmptyCard label={weaponsTechnology.improvedEfficiency.names}/>
                                    <EmptyCard label={weaponsTechnology.refiningRecipes.names}/>
                                </div>
                            </div>

                            <div className={styles.column}>
                                <ResearchCard
                                    researchItem={weaponsTechnology.momentumShield}
                                    selectedLevels={selectedResearchLevel.momentumShield}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.securityBarrier}
                                    selectedLevels={selectedResearchLevel.securityBarrier}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.apeOfSteel}
                                    selectedLevels={selectedResearchLevel.apeOfSteel}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.bulletproofChest}
                                    selectedLevels={selectedResearchLevel.bulletproofChest}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                            </div>

                            <div className={`${styles.column} ${styles.column2}`}>
                                <div className={`${styles.row}`}>
                                    <EmptyCard label={weaponsTechnology.feelingInspired.names}/>
                                    <EmptyCard label={weaponsTechnology.quickConstruction.names}/>
                                    <EmptyCard label={weaponsTechnology.masterArchitect.names}/>
                                    <EmptyCard label={weaponsTechnology.researchMania.names}/>
                                </div>
                                <div className={`${styles.row} ${styles.row3}`}>
                                    <ResearchCard
                                        researchItem={weaponsTechnology.unitedWeStand}
                                        selectedLevels={selectedResearchLevel.unitedWeStand}
                                        isUsedRelic={isUsedRelic}
                                        speedUpPercent={speedUpPercent}
                                        onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                    />
                                    <div className={styles.researchCardWrap}>
                                        <ResearchCard
                                            researchItem={weaponsTechnology.interstellarTravel}
                                            selectedLevels={selectedResearchLevel.interstellarTravel}
                                            isUsedRelic={isUsedRelic}
                                            speedUpPercent={speedUpPercent}
                                            onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.row}`}>
                                    <EmptyCard label={weaponsTechnology.superWeapons.names}/>
                                    <EmptyCard label={weaponsTechnology.armoredMechs.names}/>
                                    <EmptyCard label={weaponsTechnology.mechEnergizer.names}/>
                                    <EmptyCard label={weaponsTechnology.mechRising.names}/>
                                </div>
                            </div>

                            <div className={styles.column}>
                                <ResearchCard
                                    researchItem={weaponsTechnology.spaceWarrior}
                                    selectedLevels={selectedResearchLevel.spaceWarrior}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                            </div>

                            <div className={styles.column}>
                                <ResearchCard
                                    researchItem={weaponsTechnology.timeToSoar}
                                    selectedLevels={selectedResearchLevel.timeToSoar}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.hurricaneBullets}
                                    selectedLevels={selectedResearchLevel.hurricaneBullets}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.forceOfSpeed}
                                    selectedLevels={selectedResearchLevel.forceOfSpeed}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.chariotAssault}
                                    selectedLevels={selectedResearchLevel.chariotAssault}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                            </div>

                            <div className={styles.column}>
                                <ResearchCard
                                    researchItem={weaponsTechnology.blastCores}
                                    selectedLevels={selectedResearchLevel.blastCores}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.pulseSniper}
                                    selectedLevels={selectedResearchLevel.pulseSniper}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.hiEnergyHammer}
                                    selectedLevels={selectedResearchLevel.hiEnergyHammer}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <ResearchCard
                                    researchItem={weaponsTechnology.heavyExplosives}
                                    selectedLevels={selectedResearchLevel.heavyExplosives}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                            </div>

                            <div className={styles.column}>
                                <ResearchCard
                                    researchItem={weaponsTechnology.spaceWalk}
                                    selectedLevels={selectedResearchLevel.spaceWalk}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                            </div>

                            <div className={styles.column}>
                                <EmptyCard label={weaponsTechnology.steelriders.names}/>
                                <EmptyCard label={weaponsTechnology.skyrenders.names}/>
                                <ResearchCard
                                    researchItem={weaponsTechnology.earthshakers}
                                    selectedLevels={selectedResearchLevel.earthshakers}
                                    isUsedRelic={isUsedRelic}
                                    speedUpPercent={speedUpPercent}
                                    onSumChange={handleSumChange}
                                    setResearchItem={setResearchItem}
                                    onOpenInfoModal={setIsOpenModalInfo}
                                />
                                <EmptyCard label={weaponsTechnology.infinityTanks.names}/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>

            <Modal isOpen={isOpenSpeedBoostInfoModal} onClose={() => setIsOpenSpeedBoostInfoModal(false)}>
                {t('relicInfoModalPart1')} "<strong>{t('relicInfoModalPart2')}</strong> > <strong>{t('relicInfoModalPart3')}</strong> > <strong>{t('relicInfoModalPart4')}</strong>"
            </Modal>

            <BuildingInfoModal
                isOpen={isOpenModalInfo}
                onClose={handleCloseModalInfo}
                title={researchItem?.item?.names[lang]}
                levels={researchItem?.item?.levels}
                requirements={researchItem?.requirements}
                speedUpPercent={speedUpPercent}
                isUsedRelic={isUsedRelic}
            />
        </>
    )
}

export default ResearchT6;
