import building1Img from '../assets/images/buildings/building1.png';
import building2Img from '../assets/images/buildings/building2.png';
import building3Img from '../assets/images/buildings/building3.png';
import building4Img from '../assets/images/buildings/building4.png';
import building5Img from '../assets/images/buildings/building5.png';
import building6Img from '../assets/images/buildings/building6.png';
import building7Img from '../assets/images/buildings/building7.png';
import building8Img from '../assets/images/buildings/building8.png';
import building9Img from '../assets/images/buildings/building9.png';
import building10Img from '../assets/images/buildings/building10.png';
import building11Img from '../assets/images/buildings/building11.png';
import building12Img from '../assets/images/buildings/building12.png';
import building13Img from '../assets/images/buildings/building13.png';
import building14Img from '../assets/images/buildings/building14.png';
import building15Img from '../assets/images/buildings/building15.png';

import {
    cityCouncils,
    researchLabs,
    pilotsCamp,
    hittersCamp,
    shootersCamp,
    wallBreakersCamp,
    commandCenter,
    apesTavern,
    intelStation,
    medicalStation,
    gangEmbassy,
    defenseWalls,
    wareHouse,
    sentryTowers,
    bananaTree,
} from './buildings';


export const buildingTypes = [
    {
        id: 1,
        img: building1Img,
        type: 'cityCouncil',
        names: {
            ru: 'Городской совет',
            en: 'City Hall',
        },
        levels: cityCouncils,
    },
    {
        id: 2,
        img: building2Img,
        type: 'researchLab',
        names: {
            ru: 'Лаборатория',
            en: 'Research Lab',
        },
        levels: researchLabs,
    },
    {
        id: 3,
        img: building13Img,
        type: 'wareHouse',
        names: {
            ru: 'Склад',
            en: 'Warehouse',
        },
        levels: wareHouse,
    },
    {
        id: 4,
        img: building3Img,
        type: 'pilotsCamp',
        names: {
            ru: 'Лагерь гонщиков',
            en: 'Pilots Camp',
        },
        levels: pilotsCamp,
    },
    {
        id: 5,
        img: building4Img,
        type: 'hittersCamp',
        names: {
            ru: 'Лагерь громил',
            en: 'Hitters Camp',
        },
        levels: hittersCamp,
    },
    {
        id: 6,
        img: building5Img,
        type: 'shootersCamp',
        names: {
            ru: 'Лагерь стрелков',
            en: 'Shooters Camp',
        },
        levels: shootersCamp,
    },
    {
        id: 7,
        img: building6Img,
        type: 'wallBreakersCamp',
        names: {
            ru: 'Лагерь стеноломов',
            en: 'Wall Breakers Camp',
        },
        levels: wallBreakersCamp,
    },
    {
        id: 8,
        img: building7Img,
        type: 'commandCenter',
        names: {
            ru: 'Командный центр',
            en: 'Command Center',
        },
        levels: commandCenter,
    },
    {
        id: 9,
        img: building8Img,
        type: 'apesTavern',
        names: {
            ru: 'Таверна «Мартышка»',
            en: 'Apes Tavern',
        },
        levels: apesTavern,
    },
    {
        id: 10,
        img: building9Img,
        type: 'intelStation',
        names: {
            ru: 'Разведстанция',
            en: 'Intel Station',
        },
        levels: intelStation,
    },
    {
        id: 11,
        img: building10Img,
        type: 'medicalStation',
        names: {
            ru: 'Медпункт',
            en: 'Medical Station',
        },
        levels: medicalStation,
    },
    {
        id: 12,
        img: building11Img,
        type: 'gangEmbassy',
        names: {
            ru: 'Посольство банды',
            en: 'Gang Embassy',
        },
        levels: gangEmbassy,
    },
    {
        id: 13,
        img: building12Img,
        type: 'defenseWalls',
        names: {
            ru: 'Защитные стены',
            en: 'Defense Walls',
        },
        levels: defenseWalls,
    },
    {
        id: 14,
        img: building14Img,
        type: 'sentryTowers',
        names: {
            ru: 'Сторожевые башни',
            en: 'Sentry Towers',
        },
        levels: sentryTowers,
    },
    {
        id: 15,
        img: building15Img,
        type: 'bananaTree',
        names: {
            ru: 'Лаборатория банановых деревьев',
            en: 'Banana Tree',
        },
        levels: bananaTree,
    },
];
