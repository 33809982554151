import styles from "./Total.module.scss"
import {abbreviateNumber, secondsToDhms, sliceTime} from "../../utils/common";
import Container from "../Container/Container";
import { IconRequirement } from "../../pages/Buildings/Buildings";
import {useTranslation} from "react-i18next";

function Total({ total, speedUpPercent, onClickReset }) {
    const { i18n, t } = useTranslation()
    const lang = i18n?.resolvedLanguage

    return (
        <>
            <section className={styles.total}>
                <p className={styles.title}>{t('consumption')}:</p>
                <div className={styles.requirements}>
                    {Object.entries(total.requirements).map(([key, value]) => (
                        <div className={styles.requirement} key={key}>
                            <IconRequirement iconKey={key}/>
                            <span>{abbreviateNumber(value)}</span>
                        </div>
                    ))}
                </div>

                <p className={styles.timeWrap}>
                    <span>{t('actualTime')}:</span>
                    <span className={styles.timeBuilding}>{sliceTime(secondsToDhms(total.currentTime, lang))}</span>
                </p>
                <p className={styles.timeWrap}>
                    <span>{t('originalTime')}:</span>
                    <span>{sliceTime(secondsToDhms(total.startTime, lang))}</span>
                </p>

                <div className={styles.bottom}>
                    <button className={styles.btnReset} onClick={onClickReset}>{t('reset')}</button>
                </div>
            </section>
        </>
    )
}

export default Total
