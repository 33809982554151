import styles from './InvestPage.module.scss'
import Header from "../../components/Header/Header";
import Container from "../../components/Container/Container";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const bankCard = {
    1: {
        level: 1,
        mostInvested: 4000,
        dailyEarnings: 80,
        cost: 0,
    },
    2: {
        level: 2,
        mostInvested: 5000,
        dailyEarnings: 100,
        cost: 499,
    },
    3: {
        level: 3,
        mostInvested: 7000,
        dailyEarnings: 140,
        cost: 999,
    },
    4: {
        level: 4,
        mostInvested: 12000,
        dailyEarnings: 2400,
        cost: 999,
    },
    5: {
        level: 5,
        mostInvested: 18000,
        dailyEarnings: 360,
        cost: 1499,
    },
    6: {
        level: 6,
        mostInvested: 30000,
        dailyEarnings: 600,
        cost: 1999,
    },
}

function InvestPage() {
    const {  t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('header.item4')}</title>
            </Helmet>
            <Header/>

            <Container>
                <div className={styles.inner}>
                    <h1>{t('investScreen.title')}</h1>

                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                            <tr>
                                <th>{t('investScreen.level')}</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{t('investScreen.invest')}</td>
                                <td>{bankCard[1].mostInvested.toLocaleString()}</td>
                                <td>{bankCard[2].mostInvested.toLocaleString()}</td>
                                <td>{bankCard[3].mostInvested.toLocaleString()}</td>
                                <td>{bankCard[4].mostInvested.toLocaleString()}</td>
                                <td>{bankCard[5].mostInvested.toLocaleString()}</td>
                                <td>{bankCard[6].mostInvested.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>{t('investScreen.1DayYield')}</td>
                                <td>{bankCard[1].dailyEarnings}</td>
                                <td>{bankCard[2].dailyEarnings}</td>
                                <td>{bankCard[3].dailyEarnings}</td>
                                <td>{bankCard[4].dailyEarnings}</td>
                                <td>{bankCard[5].dailyEarnings}</td>
                                <td>{bankCard[6].dailyEarnings}</td>
                            </tr>
                            <tr>
                                <td>{t('investScreen.7DayYield')}</td>
                                <td>{(bankCard[1].dailyEarnings * 7).toLocaleString()}</td>
                                <td>{(bankCard[2].dailyEarnings * 7).toLocaleString()}</td>
                                <td>{(bankCard[3].dailyEarnings * 7).toLocaleString()}</td>
                                <td>{(bankCard[4].dailyEarnings * 7).toLocaleString()}</td>
                                <td>{(bankCard[5].dailyEarnings * 7).toLocaleString()}</td>
                                <td>{(bankCard[6].dailyEarnings * 7).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>{t('investScreen.30DayYield')}</td>
                                <td>{(bankCard[1].dailyEarnings * 30).toLocaleString()}</td>
                                <td>{(bankCard[2].dailyEarnings * 30).toLocaleString()}</td>
                                <td>{(bankCard[3].dailyEarnings * 30).toLocaleString()}</td>
                                <td>{(bankCard[4].dailyEarnings * 30).toLocaleString()}</td>
                                <td>{(bankCard[5].dailyEarnings * 30).toLocaleString()}</td>
                                <td>{(bankCard[6].dailyEarnings * 30).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>{t('investScreen.186DayYield')}</td>
                                <td>{(bankCard[1].dailyEarnings * 186).toLocaleString()}</td>
                                <td>{(bankCard[2].dailyEarnings * 186).toLocaleString()}</td>
                                <td>{(bankCard[3].dailyEarnings * 186).toLocaleString()}</td>
                                <td>{(bankCard[4].dailyEarnings * 186).toLocaleString()}</td>
                                <td>{(bankCard[5].dailyEarnings * 186).toLocaleString()}</td>
                                <td>{(bankCard[6].dailyEarnings * 186).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>{t('investScreen.365DayYield')}</td>
                                <td>{(bankCard[1].dailyEarnings * 365).toLocaleString()}</td>
                                <td>{(bankCard[2].dailyEarnings * 365).toLocaleString()}</td>
                                <td>{(bankCard[3].dailyEarnings * 365).toLocaleString()}</td>
                                <td>{(bankCard[4].dailyEarnings * 365).toLocaleString()}</td>
                                <td>{(bankCard[5].dailyEarnings * 365).toLocaleString()}</td>
                                <td>{(bankCard[6].dailyEarnings * 365).toLocaleString()}</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>{t('investScreen.costApeCoin')}</td>
                                <td>{(bankCard[2].cost).toLocaleString()}</td>
                                <td>{(bankCard[3].cost).toLocaleString()}</td>
                                <td>{(bankCard[4].cost).toLocaleString()}</td>
                                <td>{(bankCard[5].cost).toLocaleString()}</td>
                                <td>{(bankCard[6].cost).toLocaleString()}</td>
                            </tr>

                            <tr>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td className={styles.total} colSpan={2}>{t('investScreen.totalApeCoin')}:</td>
                                <td>{(bankCard[2].cost + bankCard[3].cost + bankCard[4].cost + bankCard[5].cost + bankCard[6].cost).toLocaleString()}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default InvestPage
