import styles from './KingOfApes.module.scss'
import {useTranslation} from "react-i18next"
import {EventItemIcon} from "../EventItemIcon/EventItemIcon"

const days = [
    [
        {
            title: 'koa.trainTroops.title',
            items: [
                {
                    label: 'koa.trainTroops.item1',
                    iconKey: 'troops',
                    value: 12,
                },
                {
                    label: 'koa.trainTroops.item2',
                    iconKey: 'troops',
                    value: 28,
                },
                {
                    label: 'koa.trainTroops.item3',
                    iconKey: 'troops',
                    value: 60,
                },
                {
                    label: 'koa.trainTroops.item4',
                    iconKey: 'troops',
                    value: 100,
                },
                {
                    label: 'koa.trainTroops.item5',
                    iconKey: 'troops',
                    value: 240,
                },
                {
                    label: 'koa.trainTroops.item6',
                    iconKey: 'troops',
                    value: 480,
                },
            ],
        },
        {
            title: 'koa.wheelOfFortune.title',
            items: [
                {
                    label: 'koa.wheelOfFortune.item1',
                    iconKey: 'luckyCoin',
                    value: 20000,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.defeatNeutralUnits.title',
            items: [
                {
                    label: 'koa.defeatNeutralUnits.item1',
                    iconKey: 'neutral',
                    value: 4000,
                },
                {
                    label: 'koa.defeatNeutralUnits.item2',
                    iconKey: 'neutral',
                    value: 15000,
                },
            ],
        },
        {
            title: 'koa.wheelOfFortune.title',
            items: [
                {
                    label: 'koa.wheelOfFortune.item1',
                    iconKey: 'luckyCoin',
                    value: 20000,
                },
            ],
        },
        {
            title: 'koa.trainTroops.title',
            items: [
                {
                    label: 'koa.trainTroops.item1',
                    iconKey: 'troops',
                    value: 12,
                },
                {
                    label: 'koa.trainTroops.item2',
                    iconKey: 'troops',
                    value: 28,
                },
                {
                    label: 'koa.trainTroops.item3',
                    iconKey: 'troops',
                    value: 60,
                },
                {
                    label: 'koa.trainTroops.item4',
                    iconKey: 'troops',
                    value: 100,
                },
                {
                    label: 'koa.trainTroops.item5',
                    iconKey: 'troops',
                    value: 240,
                },
                {
                    label: 'koa.trainTroops.item6',
                    iconKey: 'troops',
                    value: 480,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.resourceGathering.title',
            items: [
                {
                    label: 'koa.resourceGathering.item1',
                    iconKey: 'gathering',
                    value: 100,
                },
            ],
        },
        {
            title: 'koa.wheelOfFortune.title',
            items: [
                {
                    label: 'koa.wheelOfFortune.item1',
                    iconKey: 'luckyCoin',
                    value: 20000,
                },
            ],
        },
        {
            title: 'koa.fighterUpgrade.title',
            items: [
                {
                    label: 'koa.fighterUpgrade.item1',
                    iconKey: 'genePotion',
                    value: 32,
                },
                {
                    label: 'koa.fighterUpgrade.item2',
                    iconKey: 'legendaryMedal',
                    value: 30000,
                },
            ],
        },
        {
            title: 'koa.starUpRelics.title',
            items: [
                {
                    label: 'koa.starUpRelics.item1',
                    iconKey: 'legStone',
                    value: 3000,
                },
                {
                    label: 'koa.starUpRelics.item2',
                    iconKey: 'superStone',
                    value: 4800,
                },
            ],
        },
        {
            title: 'koa.gearAdvancement.title',
            items: [
                {
                    label: 'koa.gearAdvancement.item1',
                    iconKey: 'box',
                    value: 190,
                },
                {
                    label: 'koa.gearAdvancement.item2',
                    iconKey: 'blueprint',
                    value: 4800,
                },
                {
                    label: 'koa.gearAdvancement.item3',
                    iconKey: 'polymer',
                    value: 12000,
                },
            ],
        },
        {
            title: 'koa.equipmentUpgrade.title',
            items: [
                {
                    label: 'koa.equipmentUpgrade.item1',
                    iconKey: 'equipment',
                    value: 12,
                },
            ],
        },
        {
            title: 'koa.mechUpgrade.title',
            items: [
                {
                    label: 'koa.mechUpgrade.item1',
                    iconKey: 'mechForce',
                    value: 70,
                },
                {
                    label: 'koa.mechUpgrade.item2',
                    iconKey: 'chargeChip',
                    value: 47000,
                },
                {
                    label: 'koa.mechUpgrade.item3',
                    iconKey: 'utilityCore',
                    value: 24,
                },
            ],
        },
        {
            title: 'koa.trainTroops.title',
            items: [
                {
                    label: 'koa.trainTroops.item1',
                    iconKey: 'troops',
                    value: 12,
                },
                {
                    label: 'koa.trainTroops.item2',
                    iconKey: 'troops',
                    value: 28,
                },
                {
                    label: 'koa.trainTroops.item3',
                    iconKey: 'troops',
                    value: 60,
                },
                {
                    label: 'koa.trainTroops.item4',
                    iconKey: 'troops',
                    value: 100,
                },
                {
                    label: 'koa.trainTroops.item5',
                    iconKey: 'troops',
                    value: 240,
                },
                {
                    label: 'koa.trainTroops.item6',
                    iconKey: 'troops',
                    value: 480,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.speedUp.title',
            items: [
                {
                    label: 'koa.speedUp.item1',
                    iconKey: 'speedup',
                    value: 20,
                },
            ],
        },
        {
            title: 'koa.fighterUpgrade.title',
            items: [
                {
                    label: 'koa.fighterUpgrade.item1',
                    iconKey: 'genePotion',
                    value: 32,
                },
                {
                    label: 'koa.fighterUpgrade.item2',
                    iconKey: 'legendaryMedal',
                    value: 30000,
                },
            ],
        },
        {
            title: 'koa.starUpRelics.title',
            items: [
                {
                    label: 'koa.starUpRelics.item1',
                    iconKey: 'legStone',
                    value: 3000,
                },
                {
                    label: 'koa.starUpRelics.item2',
                    iconKey: 'superStone',
                    value: 4800,
                },
            ],
        },
        {
            title: 'koa.gearAdvancement.title',
            items: [
                {
                    label: 'koa.gearAdvancement.item1',
                    iconKey: 'box',
                    value: 190,
                },
                {
                    label: 'koa.gearAdvancement.item2',
                    iconKey: 'blueprint',
                    value: 4800,
                },
                {
                    label: 'koa.gearAdvancement.item3',
                    iconKey: 'polymer',
                    value: 12000,
                },
            ],
        },
        {
            title: 'koa.equipmentUpgrade.title',
            items: [
                {
                    label: 'koa.equipmentUpgrade.item1',
                    iconKey: 'equipment',
                    value: 12,
                },
            ],
        },
        {
            title: 'koa.mechUpgrade.title',
            items: [
                {
                    label: 'koa.mechUpgrade.item1',
                    iconKey: 'mechForce',
                    value: 70,
                },
                {
                    label: 'koa.mechUpgrade.item2',
                    iconKey: 'chargeChip',
                    value: 47000,
                },
                {
                    label: 'koa.mechUpgrade.item3',
                    iconKey: 'utilityCore',
                    value: 24,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.ultimateShowdown.title',
            items: [
                {
                    label: 'koa.ultimateShowdown.item1',
                    iconKey: 'totalVictory',
                    value: 1,
                },
                {
                    label: 'koa.ultimateShowdown.item2',
                    iconKey: 'totalVictory',
                    value: 2,
                },
                {
                    label: 'koa.ultimateShowdown.item3',
                    iconKey: 'totalVictory',
                    value: 4,
                },
                {
                    label: 'koa.ultimateShowdown.item4',
                    iconKey: 'totalVictory',
                    value: 10,
                },
                {
                    label: 'koa.ultimateShowdown.item5',
                    iconKey: 'totalVictory',
                    value: 20,
                },
            ],
        },
    ],
    [
        {
            title: 'koa.powerUp.title',
            items: [
                {
                    label: 'koa.powerUp.item1',
                    iconKey: 'power',
                    value: 16,
                },
            ],
        },
    ],
]

const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function KingOfApes() {
    const { t } = useTranslation()
    const currentDate = new Date()
    const dayNumber = currentDate.getUTCDay()

    return (
        <div className={styles.days}>
            {days.map((day, index) => (
                <div className={styles.day}>
                    <p className={`${styles.dayTitle} ${dayNumber === (index + 1) ? styles.dayTitleActive : ''}`}>
                        {t('day')} {index + 1} ({t(`daysOfTheWeek.${daysOfTheWeek[index]}`)})
                    </p>

                    {day.map((el, index) => (
                        <div className={styles.list} key={el.title}>
                            <p className={styles.listLabel}>{t(el.title)}</p>

                            {el.items.map((item, index) => (
                                <div className={styles.item} key={item.value}>
                                    <p className={styles.itemLabelWrap}>
                                        <EventItemIcon iconKey={item.iconKey} />
                                        <span className={styles.itemLabel}>{t(item.label)}</span>
                                    </p>
                                    <span className={styles.itemValue}>+{item.value?.toLocaleString()}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default KingOfApes
