import styles from './PrepareForLaunch.module.scss'
import {useTranslation} from "react-i18next";
import {EventItemIcon} from "../EventItemIcon/EventItemIcon";

const days = [
    {
        days: [1, 2],
        blocks: [
            {
                title: 'koa.defeatNeutralUnits.title',
                items: [
                    {
                        label: 'koa.defeatNeutralUnits.item1',
                        iconKey: 'neutral',
                        value: 1000,
                    },
                    {
                        label: 'koa.defeatNeutralUnits.item2',
                        iconKey: 'neutral',
                        value: 3000,
                    },
                ],
            },
        ],
    },
    {
        days: [3, 4],
        blocks: [
            {
                title: 'koa.trainTroops.title',
                items: [
                    {
                        label: 'koa.trainTroops.item3',
                        iconKey: 'troops',
                        value: 5,
                    },
                    {
                        label: 'koa.trainTroops.item4',
                        iconKey: 'troops',
                        value: 12,
                    },
                    {
                        label: 'koa.trainTroops.item5',
                        iconKey: 'troops',
                        value: 30,
                    },
                    {
                        label: 'koa.trainTroops.item6',
                        iconKey: 'troops',
                        value: 60,
                    },
                ],
            },
        ],
    },
    {
        days: [5, 6],
        blocks: [
            {
                title: 'koa.fighterUpgrade.title',
                items: [
                    {
                        label: 'koa.fighterUpgrade.item1',
                        iconKey: 'genePotion',
                        value: 10,
                    },
                    {
                        label: 'koa.fighterUpgrade.item2',
                        iconKey: 'legendaryMedal',
                        value: 10000,
                    },
                ],
            },
            {
                title: 'koa.starUpRelics.title',
                items: [
                    {
                        label: 'koa.starUpRelics.item1',
                        iconKey: 'legStone',
                        value: 1000,
                    },
                    {
                        label: 'koa.starUpRelics.item2',
                        iconKey: 'superStone',
                        value: 1600,
                    },
                ],
            },
            {
                title: 'koa.speedUp.title',
                items: [
                    {
                        label: 'koa.speedUp.item1',
                        iconKey: 'speedup',
                        value: 3,
                    },
                ],
            },
            {
                title: 'koa.gearAdvancement.title',
                items: [
                    {
                        label: 'koa.gearAdvancement.item1',
                        iconKey: 'box',
                        value: 60,
                    },
                    {
                        label: 'koa.gearAdvancement.item2',
                        iconKey: 'blueprint',
                        value: 1500,
                    },
                    {
                        label: 'koa.gearAdvancement.item3',
                        iconKey: 'polymer',
                        value: 4000,
                    },
                ],
            },
            {
                title: 'koa.equipmentUpgrade.title',
                items: [
                    {
                        label: 'koa.equipmentUpgrade.item1',
                        iconKey: 'equipment',
                        value: 5,
                    },
                ],
            },
            {
                title: 'koa.mechUpgrade.title',
                items: [
                    {
                        label: 'koa.mechUpgrade.item1',
                        iconKey: 'mechForce',
                        value: 15,
                    },
                    {
                        label: 'koa.mechUpgrade.item2',
                        iconKey: 'chargeChip',
                        value: 10000,
                    },
                    {
                        label: 'koa.mechUpgrade.item3',
                        iconKey: 'utilityCore',
                        value: 7000,
                    },
                ],
            },
        ],
    },
]

const daysOfTheWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

function PrepareForLaunch() {
    const { t } = useTranslation()
    const currentDate = new Date()
    const dayNumber = currentDate.getUTCDay()

    return (
        <div className={styles.days}>
            {days.map((day, index) => (
                <div className={styles.day}>
                    <p className={`${styles.dayTitle} ${dayNumber === day.days[0] || dayNumber === day.days[1] ? styles.dayTitleActive : ''}`}>
                        {t('days')} {day.days[0]}, {day.days[1]} ({t(`daysOfTheWeek.${daysOfTheWeek[day.days[0] - 1]}`)}, {t(`daysOfTheWeek.${daysOfTheWeek[day.days[1] - 1]}`)})
                    </p>

                    {day.blocks.map((el, index) => (
                        <div className={styles.list} key={el.title}>
                            <p className={styles.listLabel}>{t(el.title)}</p>

                            {el.items.map((item, index) => (
                                <p className={styles.item} key={item.value}>
                                    <p className={styles.itemLabelWrap}>
                                        <EventItemIcon iconKey={item.iconKey} />
                                        <span className={styles.itemLabel}>{t(item.label)}</span>
                                    </p>
                                    <span className={styles.itemValue}>+{item.value?.toLocaleString()}</span>
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default PrepareForLaunch
